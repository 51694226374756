












import Vue from 'vue';
import Dropdown from '../vendor/fish-ui/src/components/Dropdown.vue';
import Option from '../vendor/fish-ui/src/components/Option.vue';


Vue.component(Dropdown.name, Dropdown);
Vue.component(Option.name, Option);


const menuStartDefaults = [{
    'link': '/dasboard',
    'label': 'Dashboard'
}];

const menuEndDefaults = [{
    'link': '/logout',
    'label': 'Logout'
}];

const sampleSet = [
    'https://app-diva.sfo2.cdn.digitaloceanspaces.com/samples/patrick-hendry-1135682-unsplash.jpg',
    'https://app-diva.sfo2.cdn.digitaloceanspaces.com/samples/junior-pereira-73904-cropped-unsplash.jpg',
    'https://app-diva.sfo2.cdn.digitaloceanspaces.com/samples/annie-spratt-218696-unsplash.jpg',
    'https://app-diva.sfo2.cdn.digitaloceanspaces.com/samples/nishan-joomun-68360-unsplash.jpg'
];

export default {
    name: 'user-status',
    props: {
        'userData': Object,
        'navMenuItems': {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {

        }
    },
    created() {

    },
    mounted() {

    },
    methods: {
        formattedLink(option) {
            return '<a href=' + option.link + '>' + option.label + '</a>';
        },
        getMenuItems() {
            return this.menuItems;
        }
    },
    computed: {
        profileImagePath() {
            let image_path = '';

            // not logged in
            if (!this.userData) {
                return '';
            }

            if (this.userData.account_type == 'Partner') {
                if ((!this.userData.partner.profile_image) || (this.userData.partner.profile_image == '')) {
                    image_path = "https://app-diva.sfo2.cdn.digitaloceanspaces.com/site/assets/icons/org_icon.svg";
                }
                else {
                    image_path = this.userData.partner.profile_image;
                }
            }
            else {
                if ((!this.userData.user.profile_image) || (this.userData.user.profile_image == '')) {
                    image_path = "/static/assets/icons/default-profile.svg";
                }
                else {
                    image_path = this.userData.user.profile_image;
                }
            }

            return image_path;
        },
        menuItems() {
            return this.navMenuItems.concat(menuEndDefaults);
        }
    }
}

