import UserService from './services/UserService';
import * as Cookies from "js-cookie";

export enum FBStatus {
    CONNECTED = 'connected',
    NOT_AUTHORIZED = 'not_authorized',
    UNKNOWN = 'unknown'
}

export enum AccountStatus {
    NEW = 'NEW',
    ACTIVE = 'ACTIVE',
    DEACTIVATED = 'DEACTIVATED',
    PENDING = 'PENDING'
}

export interface FBAuthResponse {
    accessToken:string;
    expiresIn: string;
    reauthorize_required_in:Number;
    signedRequest: any;
    userID:string;
}

export interface FBLoginStatus {
    status: FBStatus;
    authResponse?:FBAuthResponse;
}

export default class FBChannelManager {
    public FB:any;
    public fbStatus:FBStatus;
    protected userService:UserService;
    private user_id:any;
    private csrfToken:string;
    private csrfMiddlewareToken:string;

    constructor(fb) {
        this.FB = fb;

        let csrfElement:HTMLElement = document.querySelector('[name="csrfmiddlewaretoken"]');
        this.csrfMiddlewareToken = csrfElement.getAttribute('value');
        this.csrfToken = Cookies.get('csrftoken');
        this.userService = new UserService();
    }

    checkLoginState():void {
        this.FB.getLoginStatus((response:FBLoginStatus) => {
            this.fbStatus = response.status;
            if (this.fbStatus === FBStatus.CONNECTED) {
               // get user details
               this.FB.api('/me?fields=id,first_name,last_name,email', (user) => {
                   this.registerNewAccount(user);
               });
            }
        });
    }

    registerNewAccount(user):void {
        const new_account = {
            id: user.id,
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            profile_pic: `http://graph.facebook.com/${user.id}/picture?type=normal`
        };

        const customHeaders = {
            'X-CSRFToken': this.csrfToken,
            'Accept': 'application/json'
        };

        this.userService.setEndPoint('/accounts/channels/social/create');
        this.userService.postData(new_account, customHeaders).then((resp) => {
            const setupUrl = `/accounts/channels/social/setup/${new_account.id}`;

            if (resp.status === AccountStatus.ACTIVE) {
                if (resp.validated) {
                    location.href="/dashboard";
                }
                else {
                    location.href = setupUrl;
                }
            }

            if ((resp.status === AccountStatus.NEW) || (resp.status === AccountStatus.PENDING)) {
                location.href = setupUrl;
            }

        });
    }

    getUserId(cb:Function):void {
        this.FB.api('/me?fields=id', cb);
    }

    getUserFields(fields:Array<any>, cb:Function):void {
        let f:string = fields.join(',');
        return this.FB.api(`/me?fields=${f}`, cb);
    }

    api(node:string, fields:Array<any>, cb:Function):void {
        let f:string = fields.join(',');
        let query_path:string = `/${node}?fields=${f}`;
        return this.FB.api(query_path, cb);
    }


}
