// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import * as Cookies from "js-cookie";
import PasswordChange from './components/PasswordChange.vue';
import UserService from './services/UserService';

import Collapse from './vendor/fish-ui/src/components/Collapse.vue';
import CollapseItem from './vendor/fish-ui/src/components/CollapseItem.vue';

Vue.component(Collapse.name, Collapse);
Vue.component(CollapseItem.name, CollapseItem);
declare global {
    interface Window {
        diva: any;
    }
}


declare module 'vue/types/vue' {
    interface Vue {
        showPassword(e:Event):void;
        created():void;
        passwordUpdated():void;
        closePanel():void;
    }
}


export default class PasswordChangeManager {
    private mountPoint:Element;

    constructor(el:HTMLElement) {
        this.mountPoint = el instanceof Element ? el : document.querySelector(el);
        this.run();
    }

    run():void {
        /* TODO - Figure out a way to add the application js without initializing an app */
        /* eslint-disable no-new */
        const divaUI:any = window.diva.ui.data;

        const myapp = new Vue({
            el: this.mountPoint,
            components: { PasswordChange, Collapse, CollapseItem },
            template: `
                <div class="da-password-change-component">
                    <div class="da-action-message da-action-message--notification"> {{ results_message }}</div>    
                    <fish-collapse>
                        <fish-collapse-item @click="handleCollapseItemClick" index="PW">
                            <template slot="title">
                                <span class="section-title" ref="pwSectionTitle">Change Password</span>
                            </template>
                            <div>
                                <password-change @updatepasswordclick="passwordUpdated" :status="status" ref="pwChangeComponent" />
                            </div>
                        </fish-collapse-item>
                    </fish-collapse>
                </div>`,
            data: {
                showPanel: false,
                status: {
                    type: '',
                    message: ''
                },
                results_message: ''
            },
            created() {
                //this.status = {'type': 'error', 'message': 'Password change unsuccessful'};
                //console.log("Inside the created! callback");
            },
            methods: {
                changePassword() {
                    this.showPanel = true;
                },
                passwordUpdated(e) {
                    let userService:UserService;
                    let csrfToken:string;

                    userService = new UserService();
                    csrfToken = Cookies.get('csrftoken');

                    const customHeaders = {
                        'X-CSRFToken': csrfToken,
                        'Accept': 'application/json'
                    };
            
                    userService.setEndPoint('/accounts/updatepassword/');
                    userService.postData(e, customHeaders).then((resp) => {
                        if (resp.success) {
                            this.$refs.pwChangeComponent.resetForm();
                            this.$refs.pwSectionTitle.click();
                        }

                        this.results_message = resp.message;
                        
                    });
                    
                },
                closePanel() {
                    this.showPanel = false;
                },
                handleCollapseItemClick(ev) {
                    this.results_message = "";
                }
            }
        });
        
    }
}
