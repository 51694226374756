



















import Vue from 'vue';
import VueSimpleSuggest from 'vue-simple-suggest';
import 'vue-simple-suggest/dist/styles.css'

let serviceEndpoint: FunctionConstructor;
export default {
  name: 'auto-complete-search',
  props: [
    'displayAttribute',
    'valueAttribute',
    'searchKey',
    'searchService',
    'serviceMethod',
    'searchFieldLabel',
    'containerClass',
    'showSearchIcon',
    'searchPlaceholderLabel'
  ],
  components: {VueSimpleSuggest},
  data() {
    return {
      selected: '',
      service: null
    }
  },
  created() {
    serviceEndpoint = this.searchService;
    this.service = new serviceEndpoint();
    this.hydrate();
  },
  methods: {
    hydrate() {
    },
    validateSearchOption(option) {
      return typeof option === 'object'
        && option !== null
        && option.hasOwnProperty(this.valueAttribute)
        && option.hasOwnProperty(this.displayAttribute)
        && !option[this.displayAttribute].startsWith("{");
    },
    searchOptionsList(q) {
      return this.service[this.serviceMethod].call(this.service, {[this.searchKey]: q})
        .then(res => res.options)
        .then(options => Array.isArray(options) ? options.filter(this.validateSearchOption) : []);
    },
    handleInputChange(sel) {
      this.$emit('itemSelected', sel);
    },
  },
  computed: {
    formItemClassList() {
      return this.containerClass == "" ? "" : this.containerClass;
    }
  }
}
