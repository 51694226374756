import Vue from 'vue';
import * as Cookies from 'js-cookie';
import PartnerService from './services/PartnerService';
import Modal from "./components/ModalExtended.vue";

export default class PartnerStaffManager {
  private readonly mountPoint: Element;
  private readonly partnerService: PartnerService;
  private readonly headers: Object;

  constructor(el: HTMLElement) {
    this.mountPoint = el instanceof Element ? el : document.querySelector(el);
    this.headers = {
      'X-CSRFToken': Cookies.get('csrftoken'),
      'Accept': 'application/json'
    };
    this.partnerService = new PartnerService();
    this.run().then();
  }

  async run(): Promise<void> {
    const _class = this;

    const managers = await this.partnerService.getManagers({}, this.headers);

    const managerSection = new Vue({
      components: {Modal},
      template: `
        <div>
          <section v-if="managers.length > 0" class="import-table">
            <table>
              <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Added</th>
                <th></th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="manager in managers">
                <td>{{ manager.name }}</td>
                <td>{{ manager.email }}</td>
                <td>{{ manager.date_added }}</td>
                <td>
                  <a :href="'/partner/staff/update/' + manager.id">
                    <i class="fa fa-edit"></i> Permissions
                  </a>
                </td>
                <td @click="() => handleDelete(manager)" class="clickable">
                  <i class="fa fa-user-times" style="color: #990b0b;"></i> Remove Staff
                </td>
              </tr>
              </tbody>
            </table>
          </section>
          <section v-else>
            <p>You didn't add any staff member...</p>
          </section>
          <modal :isActiveProp="isDeleteModalActive" @close="closeDeleteModal">
            <div class="manual-import-modal">
              <h3>Please confirm</h3>
              <p>
                Are you sure you want to remove access for
                <b>{{managerForDeleteFormatted}}</b>?
              </p>
              <div class="modal-item-group" style="padding: 10px 0;">
                <input type="submit"
                       value="Cancel"
                       class="da-components-button da-btn-standard"
                       @click="closeDeleteModal">
                <input type="submit"
                       value="Remove Access"
                       class="da-components-button da-components-button--critical"
                       @click="doDelete">
              </div>
            </div>
          </modal>
        </div>
      `,
      data() {
        return {
          managers: managers,
          managerForDelete: undefined,
          isDeleteModalActive: false,
        }
      },
      computed: {
        managerForDeleteFormatted() {
          return this.managerForDelete ? this.managerForDelete.name : '';
        },
      },
      methods: {
        closeDeleteModal(): void {
          this.isDeleteModalActive = false;
          this.managerForDelete = undefined;
        },
        async updateManagers(): Promise<void> {
          this.managers = await _class.partnerService.getManagers({}, _class.headers);
        },
        handleDelete(manager): void {
          this.managerForDelete = manager;
          this.isDeleteModalActive = true;
        },
        async doDelete(): Promise<void> {
          await _class.partnerService.deleteManager({'id': this.managerForDelete.id}, _class.headers);
          this.closeDeleteModal();
          await this.updateManagers();
        },
      }
    });
    managerSection.$mount(this.mountPoint);
  }
}
