import Vue from 'vue';
import ListingsSearchBox from './components/ListingsSearchBox.vue';
import ListingsAdvancedSearchBox from './components/ListingsAdvancedSearchBox.vue';
import JobFunctionFilter from './components/JobFunctionFilter.vue';
import LocationFilter from './components/LocationFilter.vue';
import JobTypeFilter from "./components/JobTypeFilter.vue";
import JobWorkTypeFilter from "./components/JobWorkTypeFilter.vue";

export default class JobsSearch {
    protected searchElement:HTMLFormElement;
    protected acSearch;
    protected app:any;
    protected appData:any;
    protected advanced:boolean;

    constructor(el:HTMLElement, advanced: boolean = false) {
        this.searchElement = el as HTMLFormElement;
        this.advanced = advanced;
        this.appData = JSON.parse(window.diva.ui.data);
        this.run();
    }

    handleItemSelected(e) {
        let listing_url = `/listings/${e.id}`;
        window.location.href = listing_url;
    }

    run() {
        const _class = this;

        // listings search
        if (this.searchElement) {
            this.acSearch = new Vue({
                el: this.searchElement,
                template:`
                    <listings-advanced-search-box v-if="advanced">

                        <job-type-filter :job-types="jobTypes" label="Job Types">
                        </job-type-filter>

                        <job-work-type-filter :job-work-types="jobWorkTypes" label="Work Types">
                        </job-work-type-filter>

                        <job-function-filter :job-functions="jobFunctions" label="Job Functions">
                        </job-function-filter>

                        <location-filter :program-locations="programLocations" label="Location">
                        </location-filter>

                    </listings-advanced-search-box>
                    <listings-search-box v-else search-type="jobs" :passed-search-query="passedSearchQuery" :title="searchBoxTitle" :basic-form-action-url="basicFormActionUrl" :advanced-form-action-url="advancedFormActionUrl">

                        <job-type-filter :job-types="jobTypes">
                        </job-type-filter>

                        <job-work-type-filter :job-work-types="jobWorkTypes">
                        </job-work-type-filter>

                        <job-function-filter :job-functions="jobFunctions">
                        </job-function-filter>

                        <location-filter :program-locations="programLocations">
                        </location-filter>

                    </listings-search-box>
                `,
                components: { JobFunctionFilter, ListingsAdvancedSearchBox, ListingsSearchBox, LocationFilter, JobTypeFilter, JobWorkTypeFilter },
                data() {
                    return {
                        advanced: _class.advanced,
                        passedSearchQuery: _class.appData.term,
                        programLocations: _class.appData.listing_locations,
                        jobFunctions: _class.appData.subjects_or_job_functions,
                        jobTypes: _class.appData.job_types,
                        jobWorkTypes: _class.appData.job_work_types,
                        searchBoxTitle: 'Search Jobs:',
                        basicFormActionUrl: '/search',
                        advancedFormActionUrl: '/search'
                    }
                },
                methods: {
                    handleItemSelected(val) {
                        let listing_url = `/listings/${val}`;
                        window.location.href = listing_url;
                    }
                }
            });
        }
    }
}
