export enum EducationalStatus {
    NOT_STATED = '',
    HIGH_SCHOOL = 'High School',
    COLLEGE = 'College',
    GRADUATE = 'Graduate School',
    YOUNG_PROFESSIONAL = 'Young Professional'
}

export interface UserEducation {
    educationalStatus: EducationalStatus;
    currentInstitution?: string;
    graduationYear: number;
    major?: string;
}

export interface RegisterState {
    firstName: string, 
    lastName: string, 
    email: string,
    education: UserEducation
}

export interface UserModel {
    firstName: string,
    lastName: string,
    email: string,
    password: string
}

export enum ActivityType {
    BOOKMARK_ARTICLE = 'BOOKMARK_ARTICLE',
    BOOKMARK_PROGRAM = 'BOOKMARK_PROGRAM',
    BOOKMARK_COUNTRY_GUIDE = 'BOOKMARK_COUNTRY_GUIDE',
    SHARE_ARTICLE = 'SHARE_ARTICLE',
    SHARE_COUNTRY_GUIDE = 'SHARE_COUNTRY_GUIDE',
    SHARE_PROGRAM = 'SHARE_PROGRAM',
    SECURITY_CHANGE_PASSWORD = 'SECURITY_CHANGE_PASSWORD',
    SECURITY_LOGIN = 'SECURITY_LOGIN',
    SECURITY_LOGIN_ATTEMPT = 'SECURITY_LOGIN_ATTEMPT'
}