// UserManager control panel microapp
import Vue, {Component} from 'vue';
import * as Cookies from 'js-cookie';
import BookmarkItemMenu from './components/BookmarkItemMenu.vue';
import ActivityService from './services/ActivityService';
import {ActivityType} from './types';


export default class BookmarksPageManager {
  protected activityService: ActivityService;
  protected csrfToken: string;

  constructor() {
    this.csrfToken = Cookies.get('csrftoken');
    this.activityService = new ActivityService();
  }

  bookmarksMenuList(el: HTMLElement) {
    const _class = this;
    const menuList = new Vue({
      components: {BookmarkItemMenu},
      data() {
        return {}
      },
      methods: {
        handleDeleteClick(data) {
          let payload = {
            target: data.id,
            type: data.type,
          };
          this.dispatchActivity(payload);
        },

        dispatchActivity(payload): void {
          const customHeaders = {
            'X-CSRFToken': _class.csrfToken,
            'Accept': 'application/json'
          };

          _class.activityService.postFormData(payload, customHeaders).then((resp) => {
            if (resp.success) {
              window.location.reload();
            }
          });
        },
        handleBookmarkClick(data) {

        }
      }

    });

    menuList.$mount(el);
  }


}
