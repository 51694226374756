import Vue from 'vue';
import Modal from './vendor/fish-ui/src/components/Modal.vue';
import Button from './vendor/fish-ui/src/components/Button.vue';

Vue.component(Modal.name, Modal);
Vue.component(Button.name, Button);

export default class ContentModal {
    protected modal;
    protected mountPoint:Element;
    protected _content:string
    protected _title:string;
    protected programService:any;
    protected confirmHandler;
    protected cancelHandler;

    constructor(el:Element) {
        this.mountPoint = el;
        this.render();
    }

    set content(val:string){
        this._content = val;
        if (this.modal)  {
            this.modal.setContent(val);
        }
    }

    set title(val:string) {
        this._title = val;
        if (this.modal) {
            this.modal.setTitle(val);
        }
    }

    show(confirm:Function, cancel:Function) {
        this.modal.setHandlers(confirm, cancel);
        this.modal.show();
    }

    hide() {
        this.modal.hide();
    }

    render() {
        const _class = this;
        this.modal = new Vue({
            components: { Modal, Button },
            data() {
                return {
                    modalContent: _class._content,
                    modalTitle: _class._title,
                    showModal: false,
                    cancelHandler: _class.cancelHandler,
                    confirmHandler: _class.confirmHandler
                }
            },
            template: `
                <fish-modal :title="modalTitle" :visible.sync="showModal" :width=650>
                    <div v-html="modalContent"></div>
                    <div class="modal-command-row">
                        <fish-button type="primary" @click="handleCancelClick">Cancel</fish-button>
                        <fish-button type="negative" @click="handleConfirmClick">Confirm</fish-button>
                    </div>
                </fish-modal>
            `,
            methods: {
                show() {
                    this.showModal = true;
                },
                hide() {
                    this.showModal = false;
                },
                setContent(val) {
                    this.modalContent = val;
                },
                setTitle(val:string) {
                    this.modalTitle = val;
                },
                setHandlers(confirmHandler:Function, cancelHandler:Function) {
                    this.confirmHandler = confirmHandler;
                    this.cancelHandler = cancelHandler;
                },
                handleCancelClick() {
                    this.showModal = false;
                    if (this.cancelHandler) {
                        this.cancelHandler()
                    }
                },
                handleConfirmClick() {
                    this.showModal = false;
                    if (this.confirmHandler) {
                        this.confirmHandler()
                    }
                }
            }
        });

        this.modal.$mount(this.mountPoint);

    }
}
