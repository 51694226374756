














































































































import Vue from 'vue';
import Multiselect from "vue-multiselect";

export default {
    name: 'opportunities-search-box',
    props: [
        'jobFunctions', 'programsAbroadTypes', 'programListingTypes',
        'programLocations', 'studyTerms', 'subjects'
    ],
    components: { Multiselect },
    data() {
        return {
            fieldTitle: "Search Opportunities",
            jobFunction: '',
            subject: '',
            searchKey: "query",
            searchLocs: [],
            searchTypes: [],
            searchAbroadTypes: [],
            searchJobTypes: [],
            searchSubjectTypes: [],
            searchQ: '',
            listingTypes: [],
            locationList: [],
            toggleLabel: 'Advanced Search',
            searchPlaceHolder: "Enter Search Term",
            submissionAttempt: false,
            term: ''
        }
    },
    created() {
        this.listingTypes = this.programListingTypes;
        this.locationList = this.programLocations;
        this.abroadTypes = this.programsAbroadTypes;
    },
    computed: {
        isSubject() {
            const job = 'JOB_INTERNSHIP_PAID';
            return this.searchTypes.length > 0 && !this.searchTypes.includes(job);
        },
        isJob() {
            const job = 'JOB_INTERNSHIP_PAID';
            return this.searchTypes.length > 0 && this.searchTypes.includes(job);
        },
        isProgramsAbroad() {
            return this.searchTypes.includes('PROGRAMS_ABROAD');
        },
        locationOptions() {
            let options = [];
            options = this.locationList.map(obj => {
                return {id: obj.id, value: obj.name};
            });

            return options;
        },
        searchTypeOptions() {
            let options = [];
            options = Object.keys(this.listingTypes).map(key => {
                return {id: key, value: this.listingTypes[key]};
            });

            return options;
        },
        abroadTypeOptions() {
            let options = [];
            options = Object.keys(this.abroadTypes).map(key => {
                return {id: key, value: this.abroadTypes[key]};
            });

            return options;
        },
        subjectTypeOptions() {
            let options = [];
            options = Object.keys(this.subjects).map(key => {
                return {id: key, value: this.subjects[key]};
            });

            return options;
        },
        studyTermOptions() {
            let options = [];
            options = Object.keys(this.studyTerms).map(key => {
                return {id: key, value: this.studyTerms[key]};
            });

            return options;
        },
        invalidState() {
            return (this.searchQ == '') && this.submissionAttempt;
        }
    },
    watch: {
        searchTypes: function() {
            if (this.searchTypes.length > 1) {
                this.searchTypes = [this.searchTypes[1]];
            }
        }
    },
    methods: {
        updateSearchType(e) {
            this.searchTypes = [e.target.value];
        },
        handleItemSelected(e) {
            this.$emit('itemSelected', e.id);
        },

        handleAdvancedToggle(e) {
            e.preventDefault();
        },

        handleFormSubmit(e) {
            e.preventDefault();
            this.submissionAttempt = true;
            e.target.submit();
        }
    }
}

