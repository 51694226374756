































import ThumbnailEditor from './ThumbnailEditor.vue';

export default {
    name: 'user-quick-edit',
    components: { ThumbnailEditor },
    props: ['user'],
    data() {
        return {
            userModel: {},
            new_img: false
        };
    },
    created() {
        this.userModel = {...this.user};
    },
    methods: {
        handleFileSelected(e) {
            this.new_img = true;
        },

        handleSaveButtonClick(e) {
            let updated = {
                first_name: this.userModel.first_name,
                last_name: this.userModel.last_name,
                tag_line: this.userModel.tag_line,
                facebook: this.userModel.facebook,
                linkedin: this.userModel.linkedin
            };

            if (this.new_img) {
                let filesList = this.$refs.thumbnailInput.getFiles();
                updated['thumbnail'] = filesList[0];
            }

            this.$emit('savebuttonclick', updated);
        }
    }
};

