










import Vue from 'vue';
import Dropdown from '../vendor/fish-ui/src/components/Dropdown.vue';

Vue.component(Dropdown.name, Dropdown);

export default {
  name: 'bookmark-item-menu',
  props: ['bookmarkId', 'bookmarkType'],
  data() {
    return {}
  },
  methods: {
    handleDelete() {
      const data = {
        'id': this.bookmarkId,
        'type': this.bookmarkType,
      };
      this.$emit('deleteclick', data)
    },
  },
}
