






import Vue from 'vue';
import DropZone from './DropZone.vue';
import FileListPreviewer from '../Previewers/FileListPreviewer.vue';
import AsyncFileUploader from './AsyncFileUploader';


export default {
    name: 'dropzone-file-uploader',
    props: ['maxFiles', 'previewer'],
    components: { DropZone, FileListPreviewer },
    data() {
        return {
            previewComponent: null,
            placeholderSrc: '/static/assets/img/camera-icon-grey.png',
            fileList: []
        }
    },
    created() {
        this.previewComponent = this.previewer ? this.previewer : FileListPreviewer;
    },
    methods: {
        handleDeleteItemClick(e){
            console.log("Delete Item clicked");
            const newList = this.fileList.filter( item => item.name != e.name );
            this.fileList = newList;
        },
        handleFilesDrop(f) {
            console.log('Files Dropped handler');
            let updated;
            const maxFileCount = Number(this.maxFiles);

            if (f.length !== this.maxFileCount) {
                updated = f.slice(0, this.maxFiles);
            } 
            else {
                updated = f;
            }
            this.fileList = updated;
        }
    }
}

