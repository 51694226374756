











//Stateless UI Component - Thumbnail upload
export default {
    name: 'thumbnail-editor',
    props: ['src'],
    data() {
        return {
            default: '/static/assets/img/da-testimonial-profile-3.png',
            thumbnail_src: ''
        }
    },
    methods: {
        handleFiles(e) {
            this.$emit('fileselected');
        },
        handleButtonClick() {
            this.$refs.fileInput.click();
        },

        getFiles() {
            return this.$refs.fileInput.files;
        }
    },
    computed: {
        
    },
    created() {
        if ((!this.src) || (this.src === '')) {
            this.thumbnail_src = this.default;
        }
        else {
            this.thumbnail_src = this.src;
        }
    }
}


