import Vue from 'vue';
import Dropdown from './vendor/fish-ui/src/components/Dropdown.vue';
import AutoCompleteSearch from './components/AutoCompleteSearch.vue';
import ListingService from './services/ListingService';

Vue.component(Dropdown.name, Dropdown)

export default class ProgramListView {
    protected listElement:HTMLElement;
    protected menuElement;
    protected acSearch;
    protected searchElement:HTMLElement;

    constructor(listEl:HTMLElement, searchEl?:HTMLElement) {
        this.listElement = listEl;
        this.searchElement = searchEl;
        this.run();
    }

    handleItemSelected(e) {
        let listing_url = `/listings/${e.listing_id}`;
        window.location.href = listing_url;
    }

    run() {
        const _class = this;
        // show menu
        this.menuElement = new Vue({
            el: this.listElement,
            components: { Dropdown },
            data() {
                return {}
            }
        });

            // listings search
            if (this.searchElement) {
                this.acSearch = new Vue({
                    el: this.searchElement,
                    template:`
                        <auto-complete-search
                            :display-attribute="displayAttr"
                            :value-attribute="valueAttr"
                            :search-key="searchKey"
                            :search-service="searchService"
                            :service-method="searchMethod"
                            :search-placeholder-label="placeholderValue"
                            :show-search-icon="true"
                            container-class="list-container-search"
                            @itemSelected="handleItemSelected"
                        />
                    `,
                    components: { AutoCompleteSearch },
                    data() {
                        return {
                            displayAttr: "listing_title",
                            valueAttr: "listing_id",
                            placeholderValue: "Search Your Listings",
                            searchKey: "query",
                            searchService: ListingService,
                            searchMethod: "search",
                            handleItemSelected: _class.handleItemSelected
                        }
                    }
                });
            }
    }
}
