import Vue from 'vue';
import ListingService from './services/ListingService';
import ListingsSearchBox from './components/ListingsSearchBox.vue';
import SubjectFilter from './components/SubjectFilter.vue';
import LocationFilter from './components/LocationFilter.vue';
import ListingsAdvancedSearchBox from "./components/ListingsAdvancedSearchBox.vue";

export default class GraduateProgramsSearch {
    protected searchElement:HTMLFormElement;
    protected acSearch;
    protected app:any;
    protected appData:any;
    protected advanced:boolean;

    constructor(el:HTMLElement, advanced: boolean = false) {
        this.searchElement = el as HTMLFormElement;
        this.appData = JSON.parse(window.diva.ui.data);
        this.advanced = advanced;
        this.run();
    }

    handleItemSelected(e) {
        let listing_url = `/listings/${e.id}`;
        window.location.href = listing_url;
    }

    run() {
        const _class = this;

        // listings search
        if (this.searchElement) {
            this.acSearch = new Vue({
                el: this.searchElement,
                template:`
                    <listings-advanced-search-box v-if="advanced">

                        <subject-filter :subjects="subjects" label="Subject">
                        </subject-filter>

                        <location-filter :program-locations="programLocations" label="Location">
                        </location-filter>

                    </listings-advanced-search-box>
                    <listings-search-box v-else search-type="graduate-programs" :passed-search-query="passedSearchQuery" :title="searchBoxTitle" :basic-form-action-url="basicFormActionUrl" :advanced-form-action-url="advancedFormActionUrl">

                        <subject-filter :subjects="subjects">
                        </subject-filter>

                        <location-filter :program-locations="programLocations">
                        </location-filter>

                    </listings-search-box>
                `,
                components: { ListingsSearchBox, ListingsAdvancedSearchBox, LocationFilter, SubjectFilter },
                data() {
                    return {
                        advanced: _class.advanced,
                        passedSearchQuery: _class.appData.term,
                        programLocations: _class.appData.listing_locations,
                        subjects: _class.appData.subjects_or_job_functions,
                        searchBoxTitle: 'Search Graduate Programs:',
                        basicFormActionUrl: '/search',
                        advancedFormActionUrl: '/search'
                    }
                },
                methods: {
                    handleItemSelected(val) {
                        let listing_url = `/listings/${val}`;
                        window.location.href = listing_url;
                    }
                }
            });
        }
    }
}
