// Registration Form State
import { RegisterState, EducationalStatus } from '../types';
import { MutationTree } from 'vuex';

export const state: RegisterState = {
    firstName: '',
    lastName: '',
    email: '',
    education: {
        educationalStatus: EducationalStatus.NOT_STATED,
        graduationYear: new Date().getFullYear()
    }
}

export const registration = {
    state
}

export const mutations: MutationTree<RegisterState> = {
    statusSelected(state, status: EducationalStatus) {
        state.education.educationalStatus = status;
    },
    schoolSelected(state, institution:string) {
        state.education.currentInstitution = institution;
    },
    majorSelected(state, major:string) {
        state.education.major = major;
    }
}