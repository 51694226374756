








import Vue from 'vue';

export default {
    props: ['files'],
    data() {
        return {
            fileList: []
        }
    },
    methods: {
        handleRemoveClick(file) {
            this.$emit('deleteitem', file);
        }
    }
}

