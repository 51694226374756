import Vue from 'vue';

export default class Breadcrumbs {
  protected mountPoint: HTMLElement;
  protected paths: Object;
  protected title: string;

  constructor(el: HTMLElement, paths: Object, title: string) {
    this.mountPoint = el instanceof Element ? el : document.querySelector(el);
    this.paths = paths;
    this.title = title;
    this.run().then(() => {
    });
  }

  async run(): Promise<void> {
    const _class = this;
    const breadcrumbsBar = new Vue({
      template: `
        <section class="breadcrumbs-bar">
          <div>
            <a href="/"><i class="fa fa-home"/> Home</a> /
            <span v-for="path in paths"><a :href="path.link">{{ path.title }}</a> /</span>
            <span class="current"><a href="#">{{ title }}</a></span>
          </div>
        </section>
      `,
      data() {
        return {
          paths: _class.paths,
          title: _class.title,
        };
      },
      mounted() {
        this.googleBreadcrumbsHandler();
      },
      methods: {
        googleBreadcrumbsHandler() {
          const head = document.getElementsByTagName('head')[0];
          const paths = [];
          paths.push({
            'title': 'Diversity Abroad',
            'link': '/',
          });
          paths.push(...(<Object[]>_class.paths));
          paths.push({
            'title': _class.title,
            'link': location.pathname,
          });
          const listItems = paths.map((path, index) =>
            `{
              "@type": "ListItem",
              "position": ${index + 1},
              "name": "${path['title']}",
              "item": "${location.protocol + '//' + location.hostname + path['link']}"
            }`
          );
          const htmlContent = `{
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": [${listItems.join(', ')}]
          }`;
          const scriptTag = document.createElement('script');
          scriptTag.type = 'application/ld+json';
          scriptTag.innerHTML = htmlContent;
          head.appendChild(scriptTag);
        },
      },
    });
    breadcrumbsBar.$mount(this.mountPoint);
  }
}
