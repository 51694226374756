










export default {
    name: 'image-previewer',
    props: ['srcFile', 'classes', 'file'],
    data() {
        return {
            classNames: [],
            imagePanel: null,
            active: false
        }
    },
    mounted() {
        this.imagePanel = this.$refs.imgEl;
    },
    methods: {
        handleFilePreview() {
            const imgfile = this.file;
            const img = this.$refs.imgPreview;
            const _component = this;

            const handleReaderLoad = function(e) {
                img.src = e.target.result;
            }

            let reader = new FileReader();
            reader.onload = handleReaderLoad;
            reader.readAsDataURL(imgfile);
        },
        handleImageClick(e) {
            this.$emit('click');
        },
        handleDeleteClick(e) {
            this.$emit('delete');
        }
    },
    computed: {
        imageSrc() {
            let src = null;

            if (this.srcFile) {
                this.active = true;
                src = this.srcFile;
            }
            else if (this.file) {
                this.active = true;
                this.handleFilePreview();
                return;
            }
            else {
                this.active = false;
                src = '/static/assets/img/camera-icon-grey-wide.png';
            }

            return src;
        }
    }
}

