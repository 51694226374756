import Vue from 'vue';
import * as Cookies from "js-cookie";
import ListingItem from "./components/ListingItem.vue";
import ListingsService from "./services/ListingsService";

export default class ListingManager {
  private readonly mountPoint: Element;
  private partnerId: string;
  private headers: Object;
  private listingsService: ListingsService;

  constructor(el: HTMLElement, partnerId: string) {
    this.mountPoint = el instanceof Element ? el : document.querySelector(el);
    this.partnerId = partnerId;
    this.headers = {
      'X-CSRFToken': Cookies.get('csrftoken'),
      'Accept': 'application/json'
    };
    this.listingsService = new ListingsService();
    this.run();
  }

  async run(): Promise<void> {
    const _class = this;
    const listings = await _class.listingsService.get({
      'partner_id': _class.partnerId
    });

    const listingsManager = new Vue({
      components: {ListingItem},
      template: `
        <div>
          <section v-for="listing in listingsForDisplay()" v-bind:key="listing.id">
            <listing-item
              :listing="listing"
              :featured="false"/>
          </section>
          <section v-if=" listings.length> count" class="load-all-listings">
            <div class="line"></div>
            <button
              @click="loadMore"
              class="da-components-button da-btn-standard first-action load-all-button">
              Load More
            </button>
            <div class="line"></div>
          </section>
        </div>
      `,
      data() {
        return {
          listings: listings,
          count: 5,
        };
      },
      methods: {
        loadMore(): void {
          this.count += 5;
        },
        listingsForDisplay(): object {
          return listings.slice(0, this.count);
        },
      },
    });
    listingsManager.$mount(this.mountPoint);
  }
}
