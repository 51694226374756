













import { checkInvalidExtensionImage } from "../utils/TypecheckUtils";


export default {
    name: 'thumbnail-input',
    props: ['src', 'fileInput', 'label'],
    data() {
        return {
            placeholderSrc: '/static/assets/img/camera-icon-grey.png',
            thumbnailSrc: '',
            active: false,
            error: '',
        };
    },
    methods: {
        handleImageClick() {
            this.fileInput.click();
        },
        handleFileSelected() {
            let filesList = this.fileInput.files;
            if (filesList.length > 0) {
                if (filesList[0].size > 2000000) {
                  this.error = 'Maximum image size is 2 MB';
                  return;
                }

                if(checkInvalidExtensionImage(filesList[0].name)) {
                  this.error = 'Please use a supported image type. File name should end with .jpg or .png';
                  return;
                }

                this.error = '';

                this.$refs.imgEl.file = filesList[0];

                let reader = new FileReader();
                reader.onload = ((aImg) => {
                    return function(e) { aImg.src = e.target.result; };
                })(this.$refs.imgEl);
                reader.readAsDataURL(filesList[0]);
                this.active = true;
            }
        },

        handleDeleteClick() {
            this.$emit('delete', this.thumbnailSrc);
            this.$refs.imgEl.src = this.placeholderSrc;
            this.fileInput.value = '';
            this.thumbnailSrc = this.placeholderSrc;
            this.active = false;
        }
    },
    created() {
        this.fileInput.addEventListener('change', (e) => {
            this.handleFileSelected();
        });

        if ((!this.src) || (this.src === '')) {
            this.thumbnailSrc = this.placeholderSrc;
            this.active = false;
        }
        else {
            this.thumbnailSrc = this.src;
            this.active = true;
        }
    }
};

