import Vue from 'vue';
//import { createClient } from '@google/maps';

declare global {
    interface Window {
        google: any;
    }
}

export default class GooglePlatformLoader {
    protected appData;
    protected callback;

    constructor() {
        this.appData = JSON.parse(window.diva.ui.data);
    }

    public loadMapsAPI() {
        return new Promise((resolve, reject) => {
            const mapsAPIKey = this.appData.api.google;
            
            const googleAPIReady = () => {
                const google = window.google;
                if (google == undefined) {
                    return reject();
                }
                else {
                    return resolve(google);
                }
                
            }

            let url = `https://maps.googleapis.com/maps/api/js?key=${mapsAPIKey}&libraries=places&callback=window.diva.ui.googleAPIReady`;
            let mapAPILoader = document.createElement('script');
            mapAPILoader.setAttribute('src', url);
            mapAPILoader.setAttribute('async', 'async');
            mapAPILoader.setAttribute('defer', 'defer');

            window.diva.ui.googleAPIReady = googleAPIReady;
            document.head.appendChild(mapAPILoader);
        })
        
    }

}