














import Vue from 'vue';
import FileUploader from './FileUploader.vue';
import FileListPreviewer from '../Previewers/FileListPreviewer.vue';

export default {
    name:'form-uploader',
    props: ['fileInput', 'previewer', 'currentFile', 'constraints','label', 'customTrigger'],
    components: { FileUploader },
    data() {
        return {
            previewSrc: null,
            selectedFiles: [],
            errors: [],
            activeLabel: '',
            activeErrors: ''
        }
    },
    created() {
        this.previewComponent = this.previewer ? this.previewer : FileListPreviewer;
        if (this.currentFile) this.previewSrc = this.currentFile;
        if (this.label) {
            this.activeLabel = this.label
        }
    },
    computed: {
        enableSelection() { return this.customTrigger ?  !this.customTrigger : this.selectedFiles.length == 0; }
    },
    methods: {
        handleClick(e) {
            //this changes based on the type of previewer, but for image, it should open the dialog
            this.fileInput.click();
        },
        async handleFileSelected(e) {
            const inputFile = e[0];
            
            if (this.constraints) {
                const validFile = await this.validateConstraints(inputFile);

                if (!validFile) {
                    this.handleValidationErrors();
                    this.fileInput.value = '';
                    return;
                }
            }
            this.selectedFiles.push(inputFile);
        },
        handleDelete(){
            this.selectedFiles = [];
            this.previewSrc = null;
            this.$emit('delete', this.currentFile);
            this.fileInput.value = '';
        },
        async validateConstraints(inputFile) {
            const {maxHeight, maxWidth, maxSize} = this.constraints;
            let dataUrl, specs;
            const fileSize = parseFloat(inputFile.size).toFixed(2);
            this.errors = [];
            this.activeErrors = '';

            try{
                dataUrl = await this.readFileAsData(inputFile);
                specs = await this.parseImageFileUpload(dataUrl);
            } catch(e) {
                this.errors.push(`Error processing your file. Only image files are accepted`);
            }

            if (fileSize > maxSize) {
                const formattedSize = (maxSize/1048576).toFixed(2);
                this.errors.push(`Maximum image size cannot exceed ${formattedSize}Mb`);
            }

            if ((specs.height > maxHeight) || (specs.width > maxWidth)) {
                this.errors.push(`Image exceeds maximum specified dimensions`);
            }

            if (this.errors.length > 0) {
                return false
            }
            else {
                return true;
            }
        },
        handleValidationErrors() {
            let errorStr = '<span class="error">';
            this.errors.forEach(e => {
                errorStr += e + '<br/>';
            });
            errorStr += '</span>';

            this.activeErrors = errorStr;
        },
        parseImageFileUpload(file) {
            const img = new Image();

            return new Promise((resolve, reject) => {
                img.addEventListener('load', () => {
                    const height = img.naturalHeight;
                    const width = img.naturalWidth;
                    resolve({height, width});
                });

                img.addEventListener('error', () => {
                    reject(new DOMException("Error processing your upload."))
                })
                img.src = file;
            });
        },
        readFileAsData(file) {
            const fileReader = new FileReader();
            return new Promise((resolve, reject) => {
                fileReader.addEventListener('load', (e:any) => {
                    resolve(e.target.result);
                });

                fileReader.addEventListener('error', (e:any) => {
                    reject(e);
                });

                fileReader.readAsDataURL(file);
            });
        }
    }
}
