import HttpService from './HttpService';

export default class UserService extends HttpService {
    constructor() {
        super('/api/v1/users');
    }

    getUser(id:any):Promise<any> {
        this.endpoint = `/api/v1/users/${id}`;
        return this.get();
    }

    setEndPoint(url:string) {
        this.endpoint = url;
    }

    // Should be deprecated in favor of postFormData
    postData(data?:Object, customHeaders?:Object):Promise<any> {
        let optionalHeaders:Object = (customHeaders ? customHeaders : {});

        let overrides = {
            method: 'POST',
            credentials: 'same-origin',
            body: this.buildFormData(data),
            headers: new Headers(optionalHeaders as HeadersInit)
        };

        let requestOptions = {...this.defaultOpts,...overrides};
        requestOptions['headers'].append('X-Requested-With', 'XMLHttpRequest');

        return this.call(requestOptions);
    }

    autoComplete(data, customHeaders) {
        this.endpoint = '/accounts/services/search'
        return this.get(data, customHeaders);
    }

    deleteUser(data, customHeaders) {
        this.endpoint = '/accounts/services/delete';
        return this.postFormData(data, customHeaders);
    }

    resendValidationEmail(data, customHeaders) {
        this.endpoint = '/cp/api/validation';
        return this.postFormData(data, customHeaders);
    }
}
