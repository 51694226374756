// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import * as Cookies from "js-cookie";
import PasswordChange from './components/PasswordChange.vue';
import UserService from './services/UserService';
import PartnerService from './services/PartnerService';
import ThumbnailEditor from './components/ThumbnailEditor.vue';
import UserQuickViewPanel from './components/UserQuickViewPanel.vue';
import MastheadImageEditor from './components/MastheadImageEditor.vue';

declare global {
    interface Window {
        diva: any;
    }
}

declare module 'vue/types/vue' {
    interface Vue {
        showPassword(e:Event):void;
        created():void;
        passwordUpdated():void;
        closePanel():void;
    }
}

export default class DashboardManager {
    protected userService:UserService;

    constructor(){
        this.namespace();
        this.registerDashboardApps();
    }

    registerDashboardApps() {
        window.diva.ui['ThumbnailEditor'] = ThumbnailEditor;
    }

    thumbnailEditor(el:HTMLElement):void {
        let editorApp = new Vue({
            components: { ThumbnailEditor },
            template: '<thumbnail-editor />'
        });

        editorApp.$mount(el);
    }

    userQuickViewPanel(el:HTMLElement):void {
        const divaUI:any = window.diva.ui.data;
        const userService:UserService = new UserService();
        let user_data = divaUI.user;
        user_data.full_name = `${user_data.first_name} ${user_data.last_name}`;

        let quickViewPanel = new Vue({
            components: { UserQuickViewPanel },
            template: '<user-quick-view-panel :userdata="user" :mode="view" @userdataupdate="handleDataSave" ref="panel" />',
            data: {
                user: user_data,
                view: 'display'
            },
            methods: {
                handleDataSave(data) {
                    // todo: Add Validation here
                    let csrfToken = Cookies.get('csrftoken');
                    const customHeaders = {
                        'X-CSRFToken': csrfToken,
                        'Accept': 'application/json'
                    };
                    userService.setEndPoint('/accounts/updatequickview/');
                    userService.postData(data, customHeaders).then((resp) => {
                        //new user = resp.user_id
                        let updatedUser = resp.data.user;
                        this.$refs.panel.setUser(updatedUser);
                        this.$refs.panel.setViewMode('display');
                    });

                }
            }
        });

        quickViewPanel.$mount(el);
    }

    partnerMastheadManager(el:HTMLElement):void {
        let image_src = '';
        const partnerService:PartnerService = new PartnerService();
        const divaUI:any = window.diva.ui.data;
        let partner_data = divaUI.partner;

        let partnerMastheadPanel = new Vue({
            components: { MastheadImageEditor },
            template: '<masthead-image-editor :src="src" @imageselected="handleImageSelected" ref="mastheadEditor" />',
            data: {
                src: image_src
            },
            methods: {
                handleImageSelected() {
                    let csrfToken = Cookies.get('csrftoken');
                    const customHeaders = {
                        'X-CSRFToken': csrfToken,
                        'Accept': 'application/json'
                    };
                    let data = {
                        image: this.$refs.getFile()
                    };

                    partnerService.setEndPoint('/partners/updatemasthead/');
                    partnerService.postData(data, customHeaders).then((resp) => {
                        //new user = resp.user_id
                        let updatedSrc = resp.data.user;
                        this.$refs.mastheadEditor(updatedSrc);
                    });
                }
            }
        });

        partnerMastheadPanel.$mount(el);
    }

    namespace() {
        if (!window.diva) window.diva = {};
        if (!window.diva.ui) window.diva.ui = {};
    }
}
