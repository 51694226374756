import * as Cookies from "js-cookie";

declare interface RequestOptions {
    endpoint?:string;
    method: string;
    headers: Headers,
    constructor?: Function;
    toString?: Function;
}

export default abstract class HttpService {
    protected endpoint:string;
    protected params:Object;
    private connection:Object;
    protected defaultOpts:RequestOptions;

    constructor(url:string){
        this.endpoint = url;
        this.defaultOpts = {
            endpoint: null,
            method: 'GET',
            headers: new Headers({
                'content-type': 'application/json'
            })
        };
    }

    protected call(opts:any, throwError:boolean=false):Promise<any> {
        let options = {...this.defaultOpts, ...opts};
        let endpoint = '';

        endpoint = options.endpoint ? options.endpoint : this.endpoint;

        return fetch(endpoint, options)
            .then(this.handleRequestErrors)
            .then((resp) => {
                return resp.json();
            })
            .catch(error => {
                if (throwError) {
                    throw(error);
                }
                console.log(error);
            });
    }

    get(data?:Object, customHeaders?:Object):Promise<any> {
        let optionalHeaders = (customHeaders ? customHeaders : {});
        let endpoint;

        let requestOptions = {...this.defaultOpts,
            ...{headers: new Headers(optionalHeaders as HeadersInit)}
        };
        requestOptions['headers'].append('X-Requested-With', 'XMLHttpRequest');

        if(data) {
            requestOptions['endpoint'] = this.buildRequestURL(data);
        }

        return this.call(requestOptions);
    }

    post(data?:Object, customHeaders?:Object):Promise<any> {
        let optionalHeaders:Object = (customHeaders ? customHeaders : {});
        let overrides = {
            method: 'POST',
            credentials: 'same-origin',
            body: JSON.stringify(data),
            headers: new Headers(optionalHeaders as HeadersInit)
        };

        let requestOptions = {...this.defaultOpts,...overrides};
        requestOptions['headers'].append('Content-Type','application/json');
        requestOptions['headers'].append('X-Requested-With', 'XMLHttpRequest');

        return this.call(requestOptions);
    }

    postFormData(data?:Object, customHeaders?:Object, throwError:boolean=false):Promise<any> {
        let optionalHeaders:Object = (customHeaders ? customHeaders : {});

        let overrides = {
            method: 'POST',
            credentials: 'same-origin',
            body: this.buildFormData(data),
            headers: new Headers(optionalHeaders as HeadersInit)
        };

        let requestOptions = {...this.defaultOpts,...overrides};
        requestOptions['headers'].append('X-Requested-With', 'XMLHttpRequest');

        return this.call(requestOptions, throwError);
    }

    put(data?:Object, customHeaders?:Object):Promise<any> {
        let optionalHeaders:Object = (customHeaders ? customHeaders : {});
        let overrides = {
            method: 'PUT',
            credentials: 'same-origin',
            body: JSON.stringify(data),
            headers: new Headers(optionalHeaders as HeadersInit)
        }

        let requestOptions = {...this.defaultOpts,...overrides};
        requestOptions['headers'].append('Content-Type','application/json');
        requestOptions['headers'].append('X-Requested-With', 'XMLHttpRequest');

        return this.call(requestOptions);
    }

    putFormData(data?:Object, customHeaders?:Object):Promise<any> {
        let optionalHeaders:Object = (customHeaders ? customHeaders : {});

        let overrides = {
            method: 'PUT',
            credentials: 'same-origin',
            body: this.buildFormData(data),
            headers: new Headers(optionalHeaders as HeadersInit)
        };

        let requestOptions = {...this.defaultOpts,...overrides};
        requestOptions['headers'].append('X-Requested-With', 'XMLHttpRequest');

        return this.call(requestOptions);
    }

    delete(data?:Object, customHeaders?:Object):Promise<any> {
        let optionalHeaders:Object = (customHeaders ? customHeaders : {});
        let overrides = {
            method: 'DELETE',
            credentials: 'same-origin',
            body: JSON.stringify(data),
            headers: new Headers(optionalHeaders as HeadersInit)
        };

        let requestOptions = {...this.defaultOpts,...overrides};
        requestOptions['headers'].append('Content-Type','application/json');
        requestOptions['headers'].append('X-Requested-With', 'XMLHttpRequest');

        return this.call(requestOptions);
    }

    uploadFile(data?:Object, customHeaders?:Object):Promise<any> {
        let optionalHeaders:Object = (customHeaders ? customHeaders : {});
        let overrides = {
            method: 'POST',
            credentials: 'same-origin',
            body: data,
            headers: new Headers(optionalHeaders as HeadersInit)
        };

        let requestOptions = {...this.defaultOpts,...overrides};
        requestOptions['headers'].append('X-Requested-With', 'XMLHttpRequest');
        requestOptions['headers'].delete('content-type');
        requestOptions['headers'].delete('Content-Type');

        return this.call(requestOptions);
    }

    /* Low level pure request object */
    request(data?:Object, options?:Object):Promise<any> {
        let requestOptions:Object = { ...this.defaultOpts, ...options};
        requestOptions['headers'].append('X-Requested-With', 'XMLHttpRequest');

        return this.call(requestOptions);
    }

    buildRequestURL(params):string {
        let keys:string[] = Object.keys(params);
        if (!keys.length) {
            return this.endpoint;
        }

        let currentUrl:string = this.endpoint + '?';

        let endpoint = keys.reduce((acc, curr) => {
            return acc + curr + '=' + params[curr] + '&'
        }, currentUrl);

        return endpoint.substr(0, endpoint.length - 1); // remove trailing '&'
    }

    buildFormData(data:Object):FormData {
        let formResponse = new FormData();

        Object.keys(data).forEach((item) => {
            formResponse.append(item, data[item]);
        })

        return formResponse;
    }

    handleRequestErrors(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    }
}
