










export default {
  name: 'destinations-guide-search',
  props: ['regionsProp'],
  data() {
    return {
      regions: this.regionsProp.sort((a, b) => (a.name > b.name) ? 1 : -1),
    };
  }
}
