
























export default {
    name: 'user-quick-view',
    props: ['user']
};
