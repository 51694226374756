// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import App from "./components/App.vue";
import UserStatus from "./components/UserStatus.vue";
import StudentRegistration from "./StudentRegisteration";
import ThumbnailInput from "./ThumbnailInput";
import FBChannelManager, { FBLoginStatus } from "./FBChannelManager";
import PasswordChangeManager from "./PasswordChangeManager";
import ProgramFormManager from "./ProgramFormManager";
import ContentActivityManager from "./ContentActivityManager";
import DashboardManager from "./DashboardManager";
import OpportunitiesPage from "./OpportunitiesPage";
import JobsSearch from "./JobsSearch";
import ProgramsAbroadSearch from "./ProgramsAbroadSearch";
import GraduateProgramsSearch from "./GraduateProgramsSearch";
import ScholarshipsSearch from "./ScholarshipsSearch";
import NewsletterForm from "./NewsletterForm";
import PassportFormManager from "./PassportFormManager";
import DestinationsGuideApp from "./DestinationsGuideApp";
import GoogleAppsManager from "./GooglePlatformLoader";
import DiversityGlobal from "./Global";
import ProgramListView from "./ProgramListView";
import ContentModal from "./ContentModal";
import BookmarksPageManager from "./BookmarksPageManager";
import FormCollectionFilter from "./FormCollectionFilters";
import DivaHomePage from "./DivaHomePage";
import ListingManager from "./ListingManager";
import FeaturedListingManager from "./FeaturedListingManager";
import AlumniPageManager from "./AlumniPageManager";
import Breadcrumbs from "./Breadcrumbs";
import PassportModal from './PassportModal';
import InfinitePartnersSection from "./InfinitePartnersSection";
import StudentImportManager from "./StudentImportManager";
import StaffLicenseManager from "./StaffLicenseManager";
import PartnerStaffManager from "./PartnerStaffManager";

Vue.config.productionTip = false;

declare global {
    interface Window {
        diva: any;
    }
}

class DiversityApp {
    mountPoint: HTMLElement;
    private newsletterApp: any;
    private userStatusApp: any;
    protected userStatusData: any;

    constructor() {
        this.mountPoint = document.querySelector("#vue-app-platform");
        this.registerPlatformApps();

        document.addEventListener("DOMContentLoaded", () => {
            this.loadPlatformApps();
            this.loadGlobalApps();
        });
    }

    loadPlatformApps(): void {
        /* eslint-disable no-new */
        /* Global component app */
        let userStatusMountPoint: HTMLElement = document.querySelector(
            ".da-user-status-component"
        );
        const userStatusMobileMountPoint: HTMLElement = document.querySelector(
            ".da-user-status-mobile-component"
        );
        const userStatusMobileImgs: Array<HTMLElement> = (document.querySelectorAll(
            ".da-user-status-mobile-component-img"
        ) as any) as Array<HTMLElement>;
        const userStatusParagrafs: Array<HTMLElement> = (document.querySelectorAll(
            ".da-user-status-mobile-component > p"
        ) as any) as Array<HTMLElement>;

        let userStatusDataComponent: HTMLElement = document.querySelector(
            ".js-status-data"
        );
        let userStatusMenuComponent: HTMLElement = document.querySelector(
            ".js-menu-data"
        );

        let mainApp = new Vue({
            components: { App },
            template: "<App/>"
        });
        this.mountPlatformApp(mainApp);

        if (!userStatusDataComponent) {
            return;
        }

        this.userStatusData = JSON.parse(
            userStatusDataComponent.dataset.userStatus
        );
        const userType = this.userStatusData.account_type;

        if (userStatusMountPoint) {
            let menuItems = JSON.parse(userStatusMenuComponent.dataset.menuOptions);

            let _self = this;
            this.userStatusApp = new Vue({
                components: { UserStatus },
                template: `<UserStatus :nav-menu-items="menuItems" :user-data="userData" ref="userStatusMenu" />`,
                data() {
                    return {
                        menuItems: menuItems,
                        userData: _self.userStatusData
                    };
                },
                methods: {
                    getUserMenuItems() {
                        return this.$refs.userStatusMenu.getMenuItems();
                    }
                }
            });
            this.userStatusApp.$mount(userStatusMountPoint);
            // this.renderUserMobileMenu();
        }

        if (userStatusMobileMountPoint) {
            const fullName = `${this.userStatusData.user.first_name} ${this.userStatusData.user.last_name}`;
            const accountType = this.userStatusData.account_type;
            const partnersProfileImg = this.userStatusData.partner !== undefined ? this.userStatusData.partner.profile_image : '';
            const usersProfileImg = this.userStatusData.user.profile_image;
            userStatusMobileImgs.forEach(img => {
                img.setAttribute(
                    "src",
                    accountType == 'Partner'
                        ? partnersProfileImg
                          ? partnersProfileImg
                          : 'https://app-diva.sfo2.cdn.digitaloceanspaces.com/site/assets/icons/org_icon.svg'
                        : usersProfileImg
                          ? usersProfileImg
                          : '/static/assets/icons/default-profile.svg'
                );

                img.setAttribute(
                    "alt",
                    fullName
                );
            });
            userStatusParagrafs.forEach((p) => {
                p.innerHTML = fullName;
            });
        }
    }

    loadGlobalApps(): void {
        //Newsletter
        const global = DiversityGlobal.getInstance();
        global.searchBoxApp();
        global.mobileMenuHandler();
        global.closeTaskNotification();
        global.showCookieDisclaimer();

        const nsForm = document.querySelector("#da-footer-form-newsletter");
        this.newsletterApp = new NewsletterForm(nsForm as HTMLElement);
    }

    registerPlatformApps(): void {
        //StudentRegistration
        window.diva.ui["FBChannelManager"] = FBChannelManager;
        window.diva.ui["StudentRegistration"] = StudentRegistration;
        window.diva.ui["ThumbnailInput"] = ThumbnailInput;
        window.diva.ui["PasswordChangeManager"] = PasswordChangeManager;
        window.diva.ui["DashboardManager"] = DashboardManager;
        window.diva.ui["ProgramFormManager"] = ProgramFormManager;
        window.diva.ui["ContentActivityManager"] = ContentActivityManager;
        window.diva.ui["OpportunitiesPage"] = OpportunitiesPage;
        window.diva.ui["JobsSearch"] = JobsSearch;
        window.diva.ui["ProgramsAbroadSearch"] = ProgramsAbroadSearch;
        window.diva.ui["GraduateProgramsSearch"] = GraduateProgramsSearch;
        window.diva.ui["ScholarshipsSearch"] = ScholarshipsSearch;
        window.diva.ui["PassportFormManager"] = PassportFormManager;
        window.diva.ui["DestinationsGuideApp"] = DestinationsGuideApp;
        window.diva.ui["GoogleAppsManager"] = GoogleAppsManager;
        window.diva.ui["ProgramListMenu"] = ProgramListView;
        window.diva.ui["ContentModal"] = ContentModal;
        window.diva.ui["ListingManager"] = ListingManager;
        window.diva.ui["FeaturedListingManager"] = FeaturedListingManager;
        window.diva.ui["AlumniPageManager"] = AlumniPageManager;
        window.diva.ui["Breadcrumbs"] = Breadcrumbs;
        window.diva.ui["PassportModal"] = PassportModal;
        window.diva.ui["InfinitePartnersSection"] = InfinitePartnersSection;
        window.diva.ui["StudentImportManager"] = StudentImportManager;
        window.diva.ui["StaffLicenseManager"] = StaffLicenseManager;
        window.diva.ui["PartnerStaffManager"] = PartnerStaffManager;
        window.diva.ui.BookmarksPageManager = BookmarksPageManager;
        window.diva.ui.FormCollectionFilter = FormCollectionFilter;
        window.diva.ui.DivaHomePage = DivaHomePage;
    }

    mountPlatformApp(app: any): void {
        const stage = document.createElement("div");
        this.mountPoint.appendChild(stage);
        app.$mount(stage);
    }

    namespace() {
        if (!window.diva) window.diva = {};
        if (!window.diva.ui) window.diva.ui = {};
        if (!window.diva.cp) window.diva.cp = {};
    }

    buildClientMenu() {
        return [
            {
                link: "/partner/dashboard",
                label: "Dashboard"
            },
            {
                link: "/listings/create",
                label: "+ New Listing"
            },
            {
                link: "/partner/listings",
                label: "Listings"
            },
            {
                link: "/partner/profile",
                label: "Organization Profile"
            },
            {
                link: "/partner/settings",
                label: "Account Settings"
            }
        ];
    }

    buildUserMenu() {
        let menuItems = [
            {
                link: "/dashboard/",
                label: "Dashboard"
            }
        ];

        if (this.userStatusData.user.premium_options) {
            let thinkific =
                this.userStatusData.user.premium_options.thinkific_url || null;

            if (thinkific) {
                menuItems.push({
                    link: thinkific,
                    label: "Abroad360 Modules"
                });
            }
        }

        menuItems = menuItems.concat([
            {
                link: "/dashboard/bookmarks",
                label: "Saved Articles"
            },
            {
                link: "/dashboard/profile",
                label: "Profile"
            },
            {
                link: "/dashboard/settings",
                label: "Settings"
            }
        ]);

        return menuItems;
    }

    renderUserMobileMenu() {
        const items = this.userStatusApp.getUserMenuItems();
        const userStatusM = document.querySelector(".js-user-nav-menu");

        let menuHtml = "";
        items.forEach(m => {
            menuHtml += `<li><a href="${m.link}">${m.label}</a></li>`;
        });

        userStatusM.innerHTML = menuHtml;
    }
}

new DiversityApp();
