
































import Vue from 'vue';
import VueSimpleSuggest from 'vue-simple-suggest';
import 'vue-simple-suggest/dist/styles.css'
import USInstitutionService from '../services/USInstitutionService';

const isTypes:Object[] = [
    { id: 'HIGH_SCHOOL' , name: 'High School' },
    { id: 'COLLEGE', name: 'College Student' },
    { id: 'GRADUATE', name: 'Graduate Student' },
    { id: 'YOUNG_PROFESSIONAL', name: 'Young Professional'}
];

const isBulkTypes:Object[] = [
    { id: 'COLLEGE', name: 'College Student' },
    { id: 'GRADUATE', name: 'Graduate Student' },
];

export default {
    name: 'institution-picker',
    props: ['statusInput', 'institutionInput', 'majorInput','collegeMajors', 'readOnlyStatus', 'bulkImport'],
    components: {VueSimpleSuggest},
    data() {
        return {
            institution: '',
            institutionType:'',
            institutionOptions: [],
            schoolService: null,
            institutionTypes: this.bulkImport ? isBulkTypes : isTypes,
            studyMajor: ''
        }
    },
    created() {
        this.schoolService = new USInstitutionService();

        this.listOfMajors = this.collegeMajors;
        this.hydrate();
    },
    methods: {
        hydrate() {
            let inst;
            this.institutionType = this.statusInput.value;

            // to preserve state, institution data is sent as a JSON string of id and name
            const institutionVal = this.institutionInput.value;
            if (institutionVal == '') {
                inst = '';
            }
            else {
                try {
                    let parsed = JSON.parse(institutionVal);
                    inst = parsed.institution_name;
                }
                catch {
                    inst = institutionVal;
                }
            }

            this.institution = inst;
            this.studyMajor = this.majorInput.value;
        },

        institutionOptionsList(q) {
            let params:Object = {
                institution_name: q
            };

            return this.schoolService.get(params).then((res) => {
                return res.schools;
            });
        },

        instituteTypeSelected(e) {
            this.statusInput.value = this.institutionType;
            this.$emit('statusChange', this.institutionType);
        },

        handleInputChange(sel) {
            const schoolData = {institution_id: sel.institution_id, institution_name: sel.institution_name};
            this.institutionInput.value = JSON.stringify(schoolData);
            this.$emit('schoolSelected', sel);
        },

        handleMajorSelected(e) {
            this.majorInput.value = this.studyMajor;
            this.$emit('majorSelected', this.studyMajor);
        }
    },
    computed: {
        shouldShowSchoolsList: function() {
            return this.institutionType == 'COLLEGE' || this.institutionType == 'GRADUATE';
        },

        currentStatus() {
            switch(this.institutionType) {
                case 'HIGH_SCHOOL':
                    return 'High School';

                case 'GRADUATE':
                    return 'Graduate';

                case 'YOUNG_PROFESSIONAL':
                    return 'Young Professional';

                case 'COLLEGE':
                default:
                    return 'College';
            }
        }
    }
}
