<template>
    <div class="da-forms-formItem" id="term-filter-list">
        <label v-if="label !== undefined">{{ label }}</label>
        <multiselect
            id="experience_types"
            v-model="terms"
            :options="studyTermOptions"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="true"
            :hide-selected="true"
            :searchable="false"
            track-by="id"
            label="value"
            :placeholder="placeholder"
        />
        <input v-for="obj in terms" type='hidden' name='STUDY_TERM' :value="obj.id"/>
    </div>
</template>

<script>

import Multiselect from "vue-multiselect";

export default {
    name: 'study-term-filter',
    props: ['studyTerms', 'label'],
    components: { Multiselect },
    data() {
        return {
            terms: [],
            placeholder: this.label ? 'Select option' : 'Select Term',
        }
    },
    computed: {
        studyTermOptions() {
            let options = [];
            options = Object.keys(this.studyTerms).map(key => {
                return {id: key, value: this.studyTerms[key]};
            });

            return options;
        }
    },
    mounted() {
      try {
        const url = new URL(window.location.href);
        const params = url.searchParams.getAll('STUDY_TERM');
        params.forEach(param => {
          const findObj = this.studyTermOptions.find(termOpt => termOpt['id'] === param);
          if (findObj !== undefined) {
            const actualObj = Object.assign({}, findObj);
            this.terms.push(actualObj);
          }
        });
      } catch {}
    },
}

</script>
