import Vue from 'vue';
import * as Cookies from 'js-cookie';
import AlumniService from './services/AlumniService';
import AlumniItem from "./components/AlumniItem.vue";
import Modal from './components/ModalExtended.vue';
import { checkInvalidExtensionImage } from './utils/TypecheckUtils';

export default class AlumniPageManager {
  private readonly mountPoint: Element;
  private readonly alumniService: AlumniService;
  private readonly partnerId: string;
  private headers: Object;

  constructor(el: HTMLElement, partnerId: string) {
    this.mountPoint = el instanceof Element ? el : document.querySelector(el);
    this.headers = {
      'X-CSRFToken': Cookies.get('csrftoken'),
      'Accept': 'application/json'
    };
    this.alumniService = new AlumniService();
    this.partnerId = partnerId;
    this.run().then();
  }

  async run(): Promise<void> {
    const _class = this;

    const alumniService: AlumniService = this.alumniService;
    const alumni = await this.alumniService.get({'partner_id': this.partnerId});

    const alumniManager = new Vue({
      components: {AlumniItem, Modal},
      template: `
        <div>
          <section class="da-partners-dashboard-section">
            <header class="da-components-simple-header ">
              <div class="header">
                <p class="title">Featured Alumni ({{alumni.length}}/2)</p>
              </div>
              <div class="line"></div>
            </header>
            <div v-for="student in alumni" v-bind:key="student.id">
              <alumni-item
                :student="student"
                @studentEditEvent="prepareForEdit"
                @studentDeleteEvent="deleteAlumn"/>
            </div>
            <div v-if="alumni.length < 2" class="content">
              <div class="img" @click="toggleModal">
                +
              </div>
              <div class="body">
                <h4 v-if="alumni.length === 0">No alumni listed yet</h4>
                <h4 v-else>Add another alum</h4>
                <p>Click to add a testimonial from your alumni</p>
              </div>
            </div>
          </section>
          <modal :isActiveProp="isModalActive" @close="closeModal">
            <div class="row">
              <h1 v-if="mode === 'add'">Add Alumni</h1>
              <h1 v-else>Edit Alumni</h1>
            </div>
            <div v-if="error !== ''" class="row">
              <p class="error">{{error}}</p>
            </div>
            <div class="row">
              <div class="column" style="width: 100%;">
                <div class="innerRow" style="width: 120px;align-items: center;">
                  <label
                    class="file-upload-label"
                    :style="{ backgroundImage: 'url(' + alumn.picture_url + ')'}"
                    for="alum_image">
                    {{ alumn.picture_url === '' ? 'Add image' : '' }}
                  </label>
                  <input @change="handleFileUpload" id="alum_image" type="file" accept="image/*"/>
                </div>
                <div class="innerRow" style="width: calc(100% - 120px);">
                  <label for="alum_name">Name:</label>
                  <input v-model="alumn.fullName" id="alum_name" type="text"/>
                </div>
              </div>
            </div>
            <div class="row">
              <label for="alumni_testimonial">Testimonial:</label>
              <textarea v-model="alumn.text" id="alumni_testimonial" type="text" rows="6"/>
              <label v-if="(244 - alumn.text.length) >= 0">Characters left: {{ 244 - alumn.text.length }}</label>
              <label v-else>Characters left: <span style="color: red;">{{ 244 - alumn.text.length }}</span></label>
            </div>
            <div class="row">
              <label for="alumni_link">Learn more link:</label>
                  <input v-model="alumn.link" id="alumni_link" type="text"/>
            </div>
            <div class="row">
              <div class="column">
                <button v-if="(244 - alumn.text.length) >= 0" @click="submitAlumn"
                        class="da-components-button da-btn-solid">Save
                </button>
                <button v-else @click="submitAlumn" class="da-components-button da-btn-solid" disabled>Save</button>
                <button @click="closeModal" class="da-components-button da-btn-standard">Cancel</button>
              </div>
            </div>
          </modal>
        </div>
      `,
      data() {
        return {
          alumni: alumni,
          isModalActive: false,
          alumn: {
            'fullName': '',
            'text': '',
            'image': undefined,
            'picture_url': '',
            'link': '',
          },
          error: '',
          mode: 'add',
        };
      },
      methods: {
        isURLvalid(str) {
          if (str === '') return true;
          const pattern = new RegExp('^(https?:\\/\\/)?(([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$','i');
          return !pattern.test(str);
        },
        toggleModal(): void {
          this.isModalActive = !this.isModalActive;
        },
        closeModal(): void {
          this.toggleModal();
          this.mode = 'add';
          this.resetAlumn();
        },
        handleFileUpload(e) {
          const files = e.target.files || e.dataTransfer.files;

          if (!files.length)
            return;

          if (checkInvalidExtensionImage(files[0].name)) {
              this.error = 'Please use a supported image type. File name should end with .jpg or .png';
              this.alumn.picture_url = '';
              this.alumn.image = undefined;
              return;
          } else if (files[0].size > 1000000) {
            this.error = 'Maximum image size is 1 MB';
            this.alumn.picture_url = '';
            this.alumn.image = undefined;
            return;
          }

          this.error = '';
          this.alumn.picture_url = URL.createObjectURL(files[0]);
          this.alumn.image = files[0];

        },
        resetAlumn() {
          this.alumn = {
            'fullName': '',
            'text': '',
            'image': undefined,
            'picture_url': '',
            'link': '',
          };
          this.error = '';
          this.mode = 'add';
        },
        validateAlumn(): boolean {
          const data = {
            ...this.alumn,
            firstName: this.alumn.fullName.split(' ')[0],
            lastName: this.alumn.fullName.split(' ')[1],
          };

          const {firstName, lastName, text, link} = data;

          if (firstName === '' || firstName === undefined || lastName === '' || lastName === undefined) {
            this.error = 'Please input full name';
            return false;
          } else if (text === '') {
            this.error = 'Please input text';
            return false;
          } else if (this.isURLvalid(link)){
            this.error = 'Please input valid URL';
            return false;
          }

          return true;
        },
        prepareForEdit(student: Object): void {
          this.mode = 'edit';
          this.alumn = student;
          this.toggleModal();
        },
        async submitAlumn(): Promise<void> {
          if (!this.validateAlumn()) {
            return;
          }

          const data = {
            ...this.alumn,
            firstName: this.alumn.fullName.split(' ')[0],
            lastName: this.alumn.fullName.split(' ')[1],
          };

          const sendRequest = this.mode === 'add' ? this.addAlumn : this.editAlumn;

          this.toggleModal();
          await sendRequest(data);

          this.updateAlumni();
          this.resetAlumn();
        },
        async addAlumn(data: Object): Promise<void> {
          await alumniService.postFormData(data, _class.headers);
        },
        async editAlumn(data: Object): Promise<void> {
          await alumniService.putFormData({...data, 'id': this.alumn.id}, _class.headers);
        },
        async deleteAlumn(studentId: number): Promise<void> {
          await alumniService.delete({'student_id': studentId}, _class.headers);
          this.updateAlumni();
        },
        async updateAlumni(): Promise<void> {
          this.alumni = await _class.alumniService.get({'partner_id': _class.partnerId});
        },
      },
    });
    alumniManager.$mount(this.mountPoint);
  }
}
