























export default {
    name: 'password-change',
    props: ['status'],
    data () {
        return {
            current_password: '',
            new_password: '',
            new_password_confirm: '',
            validationError: false,
            message : ''
        };
    },
    methods: {
        handleUpdateClick(e) {
            const params = {
                current_password: this.current_password,
                new_password: this.new_password,
                new_password_confirm: this.new_password_confirm
            };

            if (this.new_password !== this.new_password_confirm) {
                this.validationError = true;
                return false;
            }

            this.$emit('updatepasswordclick', params);
        },
        handleInputChange(e) {
            if (this.validationError) {
                this.validationError = false;
            }
        },

        resetForm() {
            this.$refs.passwordResetForm.reset();
        }
    },
    computed: {
        showGlobalMessage() {
            if ((this.status.type) && (this.status.type === 'error')) {
                return true;
            }
            else {
                return false;
            }
        }
    }
}
