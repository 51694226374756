









import Vue from 'vue';

export default {
    name: 'file-uploader',
    props: ['fileInput', 'enableSelection', 'label'],
    created() {
        this.fileInput.addEventListener('change', (e) => {
            this.handleFileSelected();
        });
    },
    methods: {
        inputClick() {
            this.fileInput.click();
        },

        handleFileSelected(e) {
            let filesList = this.fileInput.files;
            if (filesList.length > 0) {
                this.$emit('change', filesList);
            }  
        },

        reset() {
            this.fileInput.value = '';
        }
    }
}
