





































































import Vue from 'vue';
import VueSimpleSuggest from 'vue-simple-suggest';
import 'vue-simple-suggest/dist/styles.css'
import USInstitutionService from '../services/USInstitutionService';

const isTypes:Object[] = [
    { id: 'HIGH_SCHOOL' , name: 'High School' },
    { id: 'COLLEGE', name: 'College Student' },
    { id: 'GRADUATE', name: 'Graduate Student' },
    { id: 'YOUNG_PROFESSIONAL', name: 'Young Professional'}
];

export default {
    name: 'passport-education-panel',
    props: [
        'educationStatus',
        'institutionData',
        'collegeMajor',
        'collegeMajors',
        'readOnlyStatus',
        'gradYearValue',
        'gradYearList',
        'gpa',
        'eduStudyMajor',
        'skillsList',
        'skillsValue'
    ],
    components: {VueSimpleSuggest},
    data() {
        return {
            institution: '',
            institutionType:'',
            institutionOptions: [],
            schoolService: null,
            institutionTypes: isTypes,
            studyMajors: [],
            selectedSkills: [],
            graduationYear: null,
            gradYearOptions: [],
            cumulativeGPA: null,
            listOfMajors: [],
            skillsOptions: [],
            autoCompleteStyles: {
                defaultInput: 'da-components-forms-input large'
            }
        }
    },
    created() {
        this.schoolService = new USInstitutionService();

        const selectedOptions = (selected, list) => {
            //const choices = selected.split(',');
            let selections = [];
            selected.forEach(choice => {
                const ch = choice.toLowerCase();
                list.forEach(item => {
                    if ((item.id == choice) || (item.id == ch) || (item.name.toLowerCase() == ch.toLowerCase())) {
                        selections.push(item);
                    }
                })
            })
            return selections;
        }

        this.gradYearOptions = this.gradYearList;
        this.listOfMajors = this.collegeMajors;
        this.graduationYear = this.gradYearValue;
        this.cumulativeGPA = this.gpa;
        this.studyMajors = selectedOptions(this.eduStudyMajor, this.listOfMajors);
        this.skillsOptions = this.skillsList;
        this.selectedSkills = selectedOptions(this.skillsValue, this.skillsList);

        if (this.institutionData) {
            this.institution = this.institutionData.name;
        }

        this.hydrate();
    },
    methods: {
        hydrate() {
            let inst;
            this.institutionType = this.educationStatus;
        },

        institutionOptionsList(q) {
            let params:Object = {
                institution_name: q
            };

            return this.schoolService.get(params).then((res) => {
                return res.schools;
            });
        },


        instituteTypeSelected(e) {
            //this.statusInput.value = this.institutionType;
            this.$emit('status-change', this.institutionType);
        },


        handleSchoolSelectionChange(e) {
            this.$emit('school-selection-change', e.institution_id);
        },


        handleInputChange(sel) {
            const schoolData = {institution_id: sel.institution_id, institution_name: sel.institution_name};
            //this.institutionInput.value = JSON.stringify(schoolData);
            this.$emit('school-selected', sel);
        },


        handleGraduationYearChanged(e) {
            //this.gradYearInput.value = this.graduationYear;
            this.$emit('graduation-year-changed', this.graduationYear);
        },


        handleMajorSelected(e) {
            let selections = this.studyMajors.map(sel => sel.id);
            this.$emit('major-selection-changed', selections);
        },


        handleGPAChanged(e) {
            this.$emit('gpa-input-changed', this.cumulativeGPA);
        },

        handleSkillSelected(e) {
            let selections = this.selectedSkills.map(sel => sel.id);
            this.$emit('skill-selection-changed', selections);
        }

    },
    computed: {
        shouldShowSchoolsList: function() {
            return this.institutionType == 'COLLEGE' || this.institutionType == 'GRADUATE';
        },

        currentStatus() {
            switch(this.educationStatus) {
                case 'HIGH_SCHOOL':
                    return 'High School';

                case 'GRADUATE':
                    return 'Graduate';

                case 'YOUNG_PROFESSIONAL':
                    return 'Young Professional';

                case 'COLLEGE':
                default:
                    return 'College';
            }
        }
    }
}

