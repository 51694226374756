import HttpService from './HttpService';

export default class ListingService extends HttpService {
    constructor() {
        super('/listings/');
    }

    deleteListing(data:any, customHeaders) {
        this.endpoint = '/listings/api/delete';
        return this.delete(data, customHeaders);
    }

    search(data, customHeaders) {
        this.endpoint = '/listings/search';
        return this.get(data, customHeaders);
    }

    opportunities(data, customHeaders) {
        this.endpoint = '/listings/opportunities';
        return this.get(data, customHeaders);
    }
}
