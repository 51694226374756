import Vue from 'vue';
import Modal from './components/ModalExtended.vue';

export default class PassportModal {
  private readonly mountPoint: HTMLElement;
  private readonly name: string;

  constructor(mountPoint: HTMLElement, name: string) {
    this.mountPoint = mountPoint;
    this.name = name;
    this.run();
  }

  run() {
    const _class = this;

    const passportModal = new Vue({
      components: {Modal},
      template: `
        <modal :isActiveProp="isVisible" @close="closeModal">
          <div class="welcome-passport-modal">
            <h1>Hi {{ name }},</h1>
            <p>Welcome to the Diversity Abroad Community! First step, let us know more about you. Complete your passport
              and let curated resources and opportunities find you.</p>
            <div class="button-container">
              <a class="da-components-button da-btn-solid" href="/dashboard/passport">Complete My Passport</a>
              <a @click="closeModal" href="#">Skip</a>
            </div>
          </div>
        </modal>
      `,
      data() {
        return {
          name: _class.name,
          isVisible: true,
        };
      },
      methods: {
        closeModal(): void {
          this.isVisible = false;
        }
      },
    });
    passportModal.$mount(this.mountPoint);
  }
}
