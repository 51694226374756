
<template>
  <div id="app"></div>
</template>

<script>
// Global App Options
import Vue from 'vue';
// Global Base Fish UI styles
import '../vendor/fish-ui/src/styles/index.less';

export default Vue.extend({
  name: 'App',
});
</script>

