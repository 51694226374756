import * as Cookies from 'js-cookie';
import DIVAppService from './services/DIVAppService';

export default class NewsletterForm {
    form:HTMLFormElement;
    messagePanel:HTMLElement;
    csrfToken:string;
    networkService:DIVAppService;
    nameField:HTMLInputElement;
    emailField:HTMLInputElement;

    constructor(el:HTMLElement) {
        this.form = el as HTMLFormElement;
        this.messagePanel = this.form.querySelector('#messagePanel');
        this.initialize();
        this.addEventListeners();
        this.networkService = new DIVAppService('/channels/newsletter/add');
    }

    initialize():void {
        this.nameField = this.form.querySelector('input[name="name"]');
        this.emailField = this.form.querySelector('input[name="email"]');
    }

    addEventListeners():void {
        this.form.addEventListener('submit', (e) => this.handleFormSubmit(e));
    }

    handleFormSubmit(e) {
        e.preventDefault();
        this.csrfToken = Cookies.get('csrftoken');

        //get form data
        let first_name = "";
        let last_name = "";
        const full_name_array = this.nameField.value.split(" ");
        first_name = full_name_array[0];
        if (full_name_array.length > 0 && full_name_array[1] !== undefined) {
            last_name = full_name_array[1];
        }

        const formData = {
            'first_name': first_name,
            'last_name': last_name,
            'email': this.emailField.value
        };

        const customHeaders = {
            'X-CSRFToken': this.csrfToken,
            'Accept': 'application/json'
        };

        this.networkService.postFormData(formData, customHeaders).then(this.handleSubmitResponse.bind(this));
    }

    handleSubmitResponse(res) {
        let msgHTML;
        let timeOutId;

        let first_name = "";
        let last_name = "";
        const full_name_array = this.nameField.value.split(" ");
        first_name = full_name_array[0];
        if (full_name_array.length > 0 && full_name_array[1] !== undefined) {
            last_name = full_name_array[1];
        }


        if (res.success) {
            msgHTML = '<p>Thank you for subscribing!</p>';
            let nodes = this.form.querySelectorAll('input');
            let inputs = Array.prototype.slice.call(nodes);
            
            inputs.forEach((item) => item.disabled = true);
        }
        else {
            if (first_name == '' || last_name == '') {
                msgHTML = '<p>Please input your full name</p>';
            } else {
                msgHTML = '<p>There was an error processing your request</p>';
            }
        }
        this.messagePanel.innerHTML = msgHTML;
        this.messagePanel.classList.remove('hidden');

        const timeoutHandler = () => {
            this.messagePanel.innerHTML = "";
            this.messagePanel.classList.add('hidden');
            this.form.reset();
            window.clearTimeout(timeOutId);
        }

        timeOutId = window.setTimeout(timeoutHandler, 10000);
    }
}