











export default {
  name: 'modal',
  props: ['isActiveProp', 'styleProp'],
  watch: {
    isActiveProp: {
      immediate: true,
      handler(newValue, oldValue) {
        this.isActive = newValue;
      },
    },
  },
  data() {
    return {
      isActive: this.isActiveProp,
      style: this.styleProp ? this.styleProp : '',
    };
  },
}
