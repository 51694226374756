import Vue from 'vue';
import {Carousel, Slide} from 'vue-carousel';


export default class DivaHomePage {
  constructor() {
    this.homePageVerticalMenu();
    this.homePageCarousel();
  }

  homePageVerticalMenu(): void {
    const hpMenu: HTMLSelectElement = document.querySelector('.vertical-channels-nav');
    if (!hpMenu) {
      return;
    }

    hpMenu.addEventListener('change', () => {
      const sel = hpMenu.selectedIndex;

      if (!sel) {
        return; //selected index needs to be greater than 0
      }

      const selectedItem: HTMLOptionElement = hpMenu.options[sel];
      window.location.href = selectedItem.dataset.navLink;
    });
  }

  homePageCarousel(): void {
    const mountPoint: HTMLElement = document.querySelector('.da-home-testimonials');
    const mql = window.matchMedia('(max-width: 991px)');

    if (!mountPoint) return;

    return mql.matches ? this.initTestimonialAndAccolades(mountPoint) : this.initTestimonial(mountPoint);
  }

  initTestimonialAndAccolades(mountPoint): void {
    this.initTestimonial(mountPoint);

    const mountMobilePointAcc: HTMLElement = document.querySelector('.da-home-accolades');
    const mobileAcc = document.querySelector('#mobileAccoladesTemplate');

    if (!mountMobilePointAcc) return;

    mountMobilePointAcc.innerHTML = mobileAcc.innerHTML;
    this.initCarousel(mountMobilePointAcc);
  }

  initTestimonial(mountPoint): void {
    const testimonialsTemplate = document.querySelector('#testimonialsTemplate');

    mountPoint.innerHTML = testimonialsTemplate.innerHTML;
    this.initCarousel(mountPoint);
  }

  initCarousel(mountPoint: HTMLElement): void {
    const mql = window.matchMedia('(max-width: 991px)');
    const perPage = mql.matches ? 1 : 3;
    new Vue({
      el: mountPoint,
      data: {
        perPage: perPage,
        scrollPerPage: false,
        autoplay: true,
        autoplayTimeout: 5000,
        loop: true,
        spacePadding: 10,
        navigationEnabled: true,
        paginationEnabled: false,
        previousButton: `
        <div class="da-home-testimonial-grid nav-button nav-button-left">
            <div class="button"><i class="fa fa-chevron-left"></i></div>
        </div>
        `,
        nextButton: `
        <div class="da-home-testimonial-grid nav-button nav-button-right">
            <div class="button"><i class="fa fa-chevron-right"></i></div>
        </div>
        `,
      },
      components: {Carousel, Slide},
    });
  }
}
