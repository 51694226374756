import Vue from 'vue';
import * as Cookies from "js-cookie";
import ListingItem from "./components/ListingItem.vue";
import ListingsService from "./services/ListingsService";

export default class FeaturedListingManager {
  private readonly mountPoint: Element;
  private partnerId: string;
  private headers: Object;
  private listingsService: ListingsService;

  constructor(el: HTMLElement, partnerId: string) {
    this.mountPoint = el instanceof Element ? el : document.querySelector(el);
    this.partnerId = partnerId;
    this.headers = {
      'X-CSRFToken': Cookies.get('csrftoken'),
      'Accept': 'application/json'
    };
    this.listingsService = new ListingsService();
    this.run();
  }

  async run(): Promise<void> {
    const _class = this;
    const listings = await _class.listingsService.get({
      'partner_id': _class.partnerId,
      'only_featured': true
    });

    const featuredListingManager = new Vue({
      components: {ListingItem},
      template: `
        <div>
          <section v-if="listings.length > 0" class="da-partners-dashboard-section">
            <header class="da-components-simple-header ">
              <div class="header">
                <p class="title">Featured Listings ({{listings.length}}/3)</p>
              </div>
              <div class="line"></div>
            </header>
            <div v-for="listing in listings" v-bind:key="listing.id">
              <listing-item
                :listing="listing"
                :featured="true"
                @unfeature="handleUnfeature"/>
            </div>
          </section>
        </div>
      `,
      data() {
        return {
          listings: listings,
        };
      },
      methods: {
        async updateListings(): Promise<void> {
          this.listings = await _class.listingsService.get({
            'partner_id': _class.partnerId,
            'only_featured': true
          });
        },
        async handleUnfeature(id): Promise<void> {
          await _class.listingsService.putFormData({
              'partner_id': _class.partnerId,
              'listing_id': id,
            },
            _class.headers);
          await this.updateListings();
        },
      }
    });
    featuredListingManager.$mount(this.mountPoint);
  }
}
