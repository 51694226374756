









import Vue from 'vue';
import Modal from '../vendor/fish-ui/src/components/Modal.vue';
import Button from '../vendor/fish-ui/src/components/Button.vue';

Vue.component(Modal.name, Modal);
Vue.component(Button.name, Button);

export default {
    name:'confirm-modal',
    props: ['content', 'visible','title'],
    data() {
        return {
            windowTitle: '',
            modalContent: '',
            showModal: true 
        }
    },
    created() {
        this.modalContent = this.content;
        this.windowTitle = this.title;
    }
}
