
















































import Vue from 'vue';
import Multiselect from "vue-multiselect";

Vue.component("multiselect", Multiselect);

export default {
    components:{ Multiselect },
    props: [
        'collegeMajorOptions',
        'gradSchoolPlansValue',
        'internshipPlansValue',
        'gradSchoolSelectionsValue',
        'internshipSelectionsValue'
    ],
    data() {
        return {
            gradSchoolPlans: 'True',
            internshipPlans: 'True',
            gradSchoolSelections: [],
            internshipSelections: [],
            studyPrograms: '',
            studyMajors: '',
        }
    },
    created() {
        const collegeMajors = this.collegeMajorOptions;
        let formattedMajors: Object[] = [];
        this.listOfMajors = collegeMajors;

        const ids = this.listOfMajors.map(maj => maj.id);

        const selectedOptions = (selected) => {
            const choices = selected.split(',');
            let selections = [];
            choices.forEach(choice => {
                const ch = choice.toLowerCase();
                this.listOfMajors.forEach(maj => {
                    if ((maj.id == ch) || (maj.name.toLowerCase() == ch.toLowerCase())) {
                        selections.push(maj);
                    }
                })
            })
            return selections;
        }

        this.gradSchoolPlans = this.gradSchoolPlansValue;
        this.gradSchoolSelections = selectedOptions(this.gradSchoolSelectionsValue);
        this.internshipSelections = selectedOptions(this.internshipSelectionsValue);
    },

    methods: {
        handleGraduatePlansChange(e) {
            this.$emit('grad-school-plans-changed', e);
        },

        handleInternshipChange(e) {
            let selections = this.internshipSelections.map(sel => sel.id);
            console.log('Major minor fields of study changed');
            this.$emit('internship-selection-changed', selections);
        },

        handleGradSchoolStudySelected(e) {
            let selections = this.gradSchoolSelections.map(sel => sel.id);
            console.log('Grad school study changed');
            this.$emit('grad-school-selection-changed', selections);
        }
    }
}

