



































import Modal from "./ModalExtended.vue";

export default {
  components: {Modal},
  name: 'listing-item',
  props: ['listing', 'featured'],
  data() {
    return {
      subMenu: false,
      isConfirmModalActive: false,
    };
  },
  methods: {
    toggleSubMenu() {
      this.subMenu = !this.subMenu;
    },
    toggleConfirmModal(): void {
      this.isConfirmModalActive = !this.isConfirmModalActive;
    },
    handleEdit() {
      this.toggleSubMenu();
      window.location.replace(`/listings/edit/${this.listing.id}`);
    },
    handleUnfeature() {
      this.toggleConfirmModal();
    },
    handleConfirm() {
      this.$emit('unfeature', this.listing.id);
    },
    handleCancel() {
      this.toggleConfirmModal();
      this.toggleSubMenu();
    },
  },
}
