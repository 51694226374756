








export default {
    name: 'listings-advanced-search-box',
}

