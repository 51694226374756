





import Vue from 'vue';

export default {
    name: 'dropzone-uploader',
    props: ['fileInput','classNames'],
    data() {
        return {
            mouseOver: false,
            droppedFiles: [],
            classes: {
                'da-components-dropzone': true,
                'active': this.mouseOver
            } 
        };
    },
    mounted() {
        if (this.classNames) {
            (this.classNames.split(',')).forEach(cls => this.classes[cls] = true);
        }
    },
    methods: {
        handleDropEvent(ev) {
            console.log('File(s) dropped');
            // Prevent default behavior (Prevent file from being opened)
            ev.preventDefault();
            let droppedFiles = [];

            if (ev.dataTransfer.items) {
                // Use DataTransferItemList interface to access the file(s)
                for (let i = 0; i < ev.dataTransfer.items.length; i++) {
                    // If dropped items aren't files, reject them
                    if (ev.dataTransfer.items[i].kind === 'file') {
                        let file = ev.dataTransfer.items[i].getAsFile();
                        this.droppedFiles.push(file);
                    }
                }   
            } else {
            // Use DataTransfer interface to access the file(s)
                for (var i = 0; i < ev.dataTransfer.files.length; i++) {
                    console.log('... file[' + i + '].name = ' + ev.dataTransfer.files[i].name);
                    this.droppedFiles.push(ev.dataTransfer.files[i]);
                }
            } 

            // Pass event to removeDragData for cleanup
            this.removeDragData(ev);
            this.$emit('filedrop', this.droppedFiles); 
            this.droppedFiles = [];
            this.mouseOver = false;  

        },

        handleDragOver(ev) {
            ev.preventDefault();
            this.mouseOver = true;
        },

        handleDragLeave(ev) {
            ev.preventDefault();
            this.mouseOver = false;
        },

        removeDragData(ev) {
            console.log('Removing drag data');
            if (ev.dataTransfer.items) {
                // Use DataTransferItemList interface to remove the drag data
                ev.dataTransfer.items.clear();
            } else {
                // Use DataTransfer interface to remove the drag data
                ev.dataTransfer.clearData();
            }
        }
    }
}

