





































import Modal from './ModalExtended.vue';

export default {
  components: {Modal},
  name: 'alumni-item',
  props: ['student'],
  data() {
    return {
      subMenu: false,
      isConfirmModalActive: false,
    };
  },
  methods: {
    toggleSubMenu() {
      this.subMenu = !this.subMenu;
    },
    toggleConfirmModal(): void {
      this.isConfirmModalActive = !this.isConfirmModalActive;
    },
    handleEdit() {
      this.toggleSubMenu();
      this.$emit('studentEditEvent', {
        ...this.student,
        'fullName': `${this.student.first_name} ${this.student.last_name}`
      });
    },
    handleDelete() {
      this.toggleConfirmModal();
    },
    handleConfirm() {
      this.$emit('studentDeleteEvent', this.student.id);
    },
    handleCancel() {
      this.toggleConfirmModal();
      this.toggleSubMenu();
    },
  },
}
