












import Vue from "vue";
import Multiselect from "vue-multiselect";
import 'vue-multiselect/dist/vue-multiselect.min.css';



export default {
    name: 'passport-lang-selector',
    components:{ Multiselect },
    props: ['languageOptions', 'fluentInputField', 'proficientInputField',`fluentValue`, 'proficientValue'],
    data() {
        return {
            fluentLangs: [],
            proficientLangs: []
        };
    },
    created() {
        this.fluentLangs = this.fluentValue;
        this.proficientLangs = this.proficientValue;
    },
    methods: {
        handleFluentOptionSelected(selected, id) {
            console.log(selected + ' ' + id);
            //this.fluentInputField.value = this.fluentValue;
            this.$emit('fluent-language-select', {id: id, value: selected});
        },

        handleProficientOptionSelected(selected, id) {
            console.log(selected + ' ' + id);
            //this.proficientInputField.value = this.proficientValue;
            this.$emit('proficient-language-select', {id: id, value: selected});
        }
    }
};
