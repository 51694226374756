




















import Multiselect from "vue-multiselect";

export default {
    name: 'program-type-filter',
    props: ['programListingTypes', 'label'],
    components: { Multiselect },
    data() {
        return {
            searchAbroadTypes: [],
            placeholder: this.label ? 'Select option' : 'Select Experience Types',
        }
    },
    computed: {
        abroadTypeOptions() {
            let options = [];
            options = Object.keys(this.programListingTypes).map(key => {
                return {id: key, value: this.programListingTypes[key]};
            });

            return options;
        }
    },
    mounted() {
      try {
        const url = new URL(window.location.href);
        const params = url.searchParams.getAll('LST_TYPE');
        params.forEach(param => {
          const findObj = this.abroadTypeOptions.find(programType => programType['id'] === param);
          if (findObj !== undefined) {
            const actualObj = Object.assign({}, findObj);
            this.searchAbroadTypes.push(actualObj);
          }
        });
      } catch {}
    },
}

