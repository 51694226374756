import HttpService from './HttpService';


export default class ImportService extends HttpService {
  constructor() {
    super('/import/export');
  }

  downloadFile(type, students, headers, fileName) {
    fetch(`/import/export?file_type=${type}&ids=${students}`, {
      method: 'GET',
      headers: new Headers(headers)
    })
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  }
}
