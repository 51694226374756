




















import Multiselect from "vue-multiselect";

export default {
    name: 'location-filter',
    props: ['programLocations', 'label'],
    components: { Multiselect },
    data() {
        return {
            searchLocs: [],
            placeholder: this.label ? 'Select option' : 'Select Location',
        }
    },
    computed: {
        locationOptions() {
            let options = [];
            options = this.programLocations.map(obj => {
                return {id: obj.id, value: obj.name};
            });

            return options;
        }
    },
    mounted() {
      try {
        const url = new URL(window.location.href);
        const params = url.searchParams.getAll('LST_COUNTRY');
        params.forEach(param => {
          const findObj = this.locationOptions.find(location => location['id'] === param);
          if (findObj !== undefined) {
            const actualObj = Object.assign({}, findObj);
            this.searchLocs.push(actualObj);
          }
        });
      } catch {}
    },
}

