//Global Apps
import CookieDisclaimer from './components/CookieDisclaimer.vue';
import Vue from 'vue';

let _initialized = false;
let _instance = null;

class DIVAGlobal {
    protected searchBox: any;

    constructor() { }

    searchBoxApp() {
        const searchIcon = document.querySelector(".js-global-search-icon");
        const searchBox = document.querySelector(".da-global-search");

        const searchInput: HTMLInputElement = document.querySelector(
            ".js-global-search-input"
        );

        if (searchIcon) {
            searchIcon.addEventListener("click", e => {
                searchBox.classList.toggle("active");
                searchInput.focus();
            });
        }

        const mobileSearchIcon = document.querySelector('#search-nav-mobile');
        const mobileSearchBox = document.querySelector('.da-global-mobile-search');

        if (mobileSearchIcon && mobileSearchBox) {
            mobileSearchIcon.addEventListener('click', () => {
                mobileSearchBox.classList.toggle('active');
                mobileSearchIcon.classList.toggle('active');
            });
        }
    }

    closeTaskNotification() {
        const closeSpan: HTMLElement = document.querySelector('.da-reminder-close');

        if (!closeSpan) {
            return;
        }

        closeSpan.addEventListener("click", e => {
            e.preventDefault();

            fetch(`/notifications/${closeSpan.id}/`, {
              method: 'PUT',
            }).then(response => {
                if (response.status === 200) {
                    const notifications = <HTMLElement[]><any>document.querySelectorAll('.notification-bar-section');
                    notifications.forEach(notification => {
                        notification.style.display = 'none';
                    });
                    const headerHeight = (<HTMLElement>document.querySelector('.da-sticky-header-container')).offsetHeight;
                    const pageContent = <HTMLElement>document.querySelector('.da-page-content');
                    pageContent.style.marginTop = `${headerHeight}px`;
                }
            });
        });
    }

    showCookieDisclaimer() {
        const cookieDisclaimerContainer: HTMLElement = document.querySelector('#cookie-disclaimer-container');
        const show: string = localStorage.getItem('show-cookie-disclamer');
        let cookieDisclaimer: Vue;
        fetch('/profiles/cookie/')
            .then(response => response.json())
            .then(preference => {
                if (!preference.cookies_accepted) {
                    cookieDisclaimer = new Vue({
                        components: {CookieDisclaimer},
                        template: `<CookieDisclaimer logged="yes" />`,
                    });
                }
            })
            .catch(() => {
                if (show !== 'accepted') {
                    cookieDisclaimer = new Vue({
                        components: {CookieDisclaimer},
                        template: `<CookieDisclaimer logged="no" />`,
                    });
                }
            })
            .finally(() => {
                if (cookieDisclaimer) {
                    cookieDisclaimer.$mount(cookieDisclaimerContainer);

                    const contentSection = <HTMLElement>document.querySelector('.destination-guide-content-section');
                    if (contentSection) {
                      const cdEl = <HTMLElement>document.querySelector('.da-global-cookie-disclaimer');
                      contentSection.style.bottom = `${cdEl.offsetHeight}px`;
                    }
                }
            });
    }

    mobileMenuHandler() {
        const mobileMenuTrigger: HTMLElement = document.querySelector(
            "#mobile-menu"
        );

        const mobileUserMenuTrigger: HTMLElement = document.querySelector(
            "#mobile-user-menu"
        );

        const mobileMenuCloseTrigger: HTMLElement = document.querySelector(
            "#mobile-menu-close-icon"
        );

        const mobileUserMenuCloseTrigger: HTMLElement = document.querySelector(
            "#mobile-user-menu-close-icon"
        );

        const mobileMenu: HTMLElement = document.querySelector(
            "#mobile-menu-container"
        );

        const mobileUserMenu: HTMLElement = document.querySelector(
            "#mobile-user-menu-container"
        );

        if (!mobileMenuTrigger) {
            return;
        }

        const toggleMenu = (e) => {
            e.preventDefault();
            mobileMenu.classList.toggle('da-global-mobile-menu-active');
        }

        const toggleUserMenu = (e) => {
            e.preventDefault();
            mobileUserMenu.classList.toggle('da-global-mobile-user-menu-active');
        }

        mobileMenuTrigger.addEventListener("click", toggleMenu);
        mobileMenuCloseTrigger.addEventListener("click", toggleMenu);

        const userSubMenuOpp: HTMLElement = document.querySelector(
            "#mobile-submenu-opportunities"
        );

        const subMenuOppIcon: HTMLElement = document.querySelector(
            "#mobile-submenu-opportunities-icon"
        );

        const subMenuOppHeader: HTMLElement = document.querySelector(
            "#mobile-submenu-opportunities-header"
        );

        const userSubMenuArt: HTMLElement = document.querySelector(
            "#mobile-submenu-articles"
        );

        const subMenuArtIcon: HTMLElement = document.querySelector(
            "#mobile-submenu-articles-icon"
        );

        const subMenuArtHeader: HTMLElement = document.querySelector(
            "#mobile-submenu-articles-header"
        );

        const userSubMenuCom: HTMLElement = document.querySelector(
            "#mobile-submenu-community"
        );

        const subMenuComIcon: HTMLElement = document.querySelector(
            "#mobile-submenu-community-icon"
        );

        const subMenuComHeader: HTMLElement = document.querySelector(
            "#mobile-submenu-community-header"
        );

        const userSubMenuAbout: HTMLElement = document.querySelector(
            "#mobile-submenu-about"
        );

        const subMenuAboutIcon: HTMLElement = document.querySelector(
            "#mobile-submenu-about-icon"
        );

        const subMenuAboutHeader: HTMLElement = document.querySelector(
            "#mobile-submenu-about-header"
        );

        const handleSubMenuClick = (subMenu: HTMLElement, iconEl: HTMLElement, header: HTMLElement) => {
            subMenu.classList.toggle('da-global-mobile-submenu-active');
            iconEl.classList.toggle('fa-angle-down');
            iconEl.classList.toggle('fa-angle-up');
            header.classList.toggle('da-global-mobile-submenu-header-active');
        };

        if (subMenuOppIcon && subMenuArtIcon && subMenuComIcon && subMenuAboutIcon) {
            subMenuOppIcon.addEventListener("click", e => {
                e.preventDefault();
                handleSubMenuClick(userSubMenuOpp, subMenuOppIcon, subMenuOppHeader);
            });
            subMenuArtIcon.addEventListener("click", e => {
                e.preventDefault();
                handleSubMenuClick(userSubMenuArt, subMenuArtIcon, subMenuArtHeader);
            });
            subMenuComIcon.addEventListener("click", e => {
                e.preventDefault();
                handleSubMenuClick(userSubMenuCom, subMenuComIcon, subMenuComHeader);
            });
            subMenuAboutIcon.addEventListener("click", e => {
                e.preventDefault();
                handleSubMenuClick(userSubMenuAbout, subMenuAboutIcon, subMenuAboutHeader);
            });
        }

        if (mobileUserMenuTrigger && mobileUserMenuCloseTrigger) {
          mobileUserMenuTrigger.addEventListener("click", toggleUserMenu);
          mobileUserMenuCloseTrigger.addEventListener("click",toggleUserMenu);
        }
    }

    featuredProgramsHeight() {
        const featuredPrograms: Array<HTMLElement> = (document.querySelectorAll('.feature-card')) as any as Array<HTMLElement>;

        const adjustHeightByWidth = (array) => {
            array.forEach((program) => {
                program.style.height = getComputedStyle(program).width;
            });
        }

        adjustHeightByWidth(featuredPrograms);

        window.addEventListener('resize', () => {
            adjustHeightByWidth(featuredPrograms);
        });
    }

}

export default class DiversityGlobal {
    static getInstance() {
        if (_initialized == false) {
            _instance = new DIVAGlobal();
            _initialized = true;
            return _instance;
        } else {
            return _instance;
        }
    }
}
