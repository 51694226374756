import Vue from 'vue';
import DestinationsGuideSearch from './components/DestinationsGuideSearch.vue';

export default class DestinationsGuideApp {
  protected mountPoint: HTMLElement;
  protected appData: any;

  constructor(el: HTMLElement) {
    this.mountPoint = el;
    this.appData = JSON.parse(window.diva.ui.data);
    this.run();
  }

  run() {
    const _self = this;

    const destinationsGuideApp = new Vue({
      components: {DestinationsGuideSearch},
      template: `
        <destinations-guide-search :regions-prop="regions"/>
      `,
      data() {
        return {
          regions: _self.appData.regions,
        }
      }
    });

    destinationsGuideApp.$mount(this.mountPoint);
  }
}
