import HttpService from './HttpService';

export default class PartnerService extends HttpService {
  constructor() {
    super('/api/v1/partner');
  }

  getPartner(id: any): Promise<any> {
    this.endpoint = `/api/v1/partner/${id}`;
    return this.get();
  }

  setEndPoint(url: string) {
    this.endpoint = url;
  }

  postData(data?: Object, customHeaders?: Object): Promise<any> {
    let optionalHeaders: Object = (customHeaders ? customHeaders : {});

    let overrides = {
      method: 'POST',
      credentials: 'same-origin',
      body: this.buildFormData(data),
      headers: new Headers(optionalHeaders as HeadersInit)
    };

    let requestOptions = {...this.defaultOpts, ...overrides};
    requestOptions['headers'].append('X-Requested-With', 'XMLHttpRequest');

    return this.call(requestOptions);
  }

  autoComplete(data, customHeaders) {
    this.endpoint = '/partner/services/search';
    return this.get(data, customHeaders);
  }

  deletePartner(data, customHeaders) {
    this.endpoint = '/partner/services/delete';
    return this.get(data, customHeaders);
  }

  suspendPartner(data, customHeaders) {
    this.endpoint = '/partner/services/suspend';
    return this.get(data, customHeaders);
  }

  resendValidationEmail(data, customHeaders) {
    this.endpoint = '/cp/api/validation';
    return this.postFormData(data, customHeaders);
  }

  exportStudents(headers: any, partner_id: string, partner_slug: string): void {
    fetch(`/import/admin/export?partner_id=${partner_id}`, {
      method: 'GET',
      headers: new Headers(headers)
    })
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${partner_slug}_exported.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  }

  getManagers(data, customHeaders) {
    this.endpoint = '/partner/staff/api';
    return this.get(data, customHeaders);
  }

  deleteManager(data, customHeaders) {
    this.endpoint = '/partner/staff/api';
    return this.delete(data, customHeaders);
  }
}
