











//Stateless UI Component - Masthead Image
export default {
    name: 'masthead-image-editor',
    props: ['src'],
    data() {
        return {
            default: '/static/assets/img/damas-607970-unsplash.jpg',
            image_src: ''
        }
    },
    methods: {
        handleFiles(e) {
            this.$emit('fileselected');
        },
        handleButtonClick() {
            this.$refs.fileInput.click();
        },

        getFile() {
            return this.$refs.fileInput.files;
        },

        setImageSrc(val) {
            this.image_src = val;
        }
    },
    computed: {
        
    },
    created() {
        if ((!this.src) || (this.src === '')) {
            this.image_src = this.default;
        }
        else {
            this.image_src = this.src;
        }
    }
}


