// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import Vuex, { Store, StoreOptions } from 'vuex';
import CollegePicker from './components/CollegePicker.vue';
import InstitutionPicker from './components/InstitutionPicker.vue';
import { registerStore as store } from './store';

Vue.config.productionTip = false;

declare global {
    interface Window {
        diva: any;
    }
}


export default class StudentRegistration {
    private schoolFormPanel:Element;
    private registrationForm:HTMLFormElement;
    protected statusInput:HTMLInputElement;
    protected schoolInput:HTMLInputElement;
    protected majorInput: HTMLInputElement;
    protected bulkImport: boolean;

    constructor(bulkImport:boolean=false) {
        this.schoolFormPanel = document.querySelector('.js-da-school-picker');
        this.registrationForm = document.querySelector('.js-da-forms-register');
        this.statusInput = this.registrationForm.querySelector('input[name="current_status"]');
        this.schoolInput = this.registrationForm.querySelector('input[name="school"]');
        this.majorInput = this.registrationForm.querySelector('input[name="major"]');
        this.bulkImport = bulkImport;

        this.registerEventListeners();
        this.loadApps();
    }

    loadApps():void {
        /* TODO - Figure out a way to add the application js without initializing an app */
        /* eslint-disable no-new */
        const divaUI:any = window.diva.ui.data;
        const self = this;

        new Vue({
            el: this.schoolFormPanel,
            components: { InstitutionPicker },
            template:
            `<InstitutionPicker
                :statusInput="statusInput"
                :institutionInput="schoolInput"
                :majorInput="majorInput"
                :collegeMajors="listOfMajors"
                :readOnlyStatus="readOnly"
                :bulkImport="bulkImport"
            />`,
            store,
            data: {
                statusInput: self.statusInput,
                schoolInput: self.schoolInput,
                majorInput: self.majorInput,
                appForm: self.registrationForm,
                listOfMajors: divaUI.college_majors,
                readOnly: false,
                bulkImport: self.bulkImport,
            },
            methods: {

            }
        });
    }

    registerEventListeners():void {
        this.registrationForm.addEventListener('submit', (e) => this.formSubmitted(e));
    }

    formSubmitted(e):boolean {
        e.preventDefault();

        this.registrationForm.submit();
        return false;
    }
}
