


















import Multiselect from "vue-multiselect";

export default {
    name: 'subject-filter',
    props: ['subjects', 'label'],
    components: { Multiselect },
    data() {
        return {
            searchSubjectTypes: [],
            placeholder: this.label ? 'Select option' : 'Select Subject',
        }
    },
    mounted() {
      try {
        const url = new URL(window.location.href);
        const params = url.searchParams.getAll('SUBJECTS');
        params.forEach(param => {
          if (this.subjects.includes(param)) {
            this.searchSubjectTypes.push(param);
          }
        });
      } catch {}
    },
}

