


















import Multiselect from "vue-multiselect";

export default {
    name: 'job-function-filter',
    props: ['jobFunctions', 'label'],
    components: { Multiselect },
    data() {
        return {
            searchJobTypes: [],
            placeholder: this.label ? 'Select option' : 'Select Job Function',
        }
    },
    mounted() {
      try {
        const url = new URL(window.location.href);
        const params = url.searchParams.getAll('LST_JOB_FUNC');
        params.forEach(param => {
          if (this.jobFunctions.includes(param)) {
            this.searchJobTypes.push(param);
          }
        });
      } catch {}
    },
}

