import Vue from 'vue';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import Multiselect from "vue-multiselect";
import VueQuill from 'vue-quill';
import ThumbnailInput from './components/ThumbnailInput.vue';
import DropzoneFileUploader from './components/Uploaders/DropzoneFileUploader.vue';
import DatePicker from './vendor/fish-ui/src/components/DatePicker.vue';

import Select from './vendor/fish-ui/src/components/Select.vue';
import Checkbox from './vendor/fish-ui/src/components/Checkbox.vue';
import Checkboxes from './vendor/fish-ui/src/components/Checkboxes.vue';
import Option from './vendor/fish-ui/src/components/Option.vue';

import FormUploader from './components/Uploaders/FormUploader.vue'
import ImagePreviewer from './components/Previewers/ImagePreviewer.vue';
import ContentModal from './ContentModal';
import ListingService from './services/ListingService';
import * as Cookies from "js-cookie";


// register globally
Vue.component("multiselect", Multiselect);
Vue.component(DatePicker.name, DatePicker);
Vue.component(Checkbox.name, Checkbox);
Vue.component(Checkboxes.name, Checkboxes);
Vue.component(Option.name, Option);
Vue.component(Select.name, Select);

Vue.use(VueQuill);

const enum ListingType {
    JOB_INTERNSHIP_PAID = 'JOB_INTERNSHIP_PAID',
    INTERN_ABROAD = 'INTERN_ABROAD',
    STUDY_ABROAD = 'STUDY_ABROAD',
    GRADUATE_SCHOOL = 'GRADUATE_SCHOOL',
    UNDERGRAD_PROGRAMS = 'UNDERGRAD_PROGRAMS',
    TEACH_ABROAD = 'TEACH_ABROAD',
    VOLUNTEER_ABROAD = 'VOLUNTEER_ABROAD',
    SCHOLARSHIPS = 'SCHOLARSHIPS'
};

export default class ProgramFormManager {
    mountPoint:HTMLFormElement;
    appData:any;
    listingType:ListingType;
    subjectData:any;
    subjectMountPoint:HTMLElement;
    subjectInput:HTMLInputElement;
    private formApp:any;
    protected thumbnailFileInput:HTMLElement;
    private submitBtn:HTMLButtonElement;
    protected thumbnailInput:HTMLInputElement;
    protected headerImageFileInput:HTMLInputElement;
    protected headerImageInput:HTMLInputElement;
    protected featuredListingInput:HTMLInputElement;
    protected featuredHeaderLabel:HTMLElement;
    protected featureFileUploaderLbl:string;

    protected contentModal:ContentModal;
    protected deleteBtn:Element;
    protected programService:ListingService;

    protected countriesData:string;

    constructor(el:HTMLFormElement) {
        this.mountPoint = el;
        this.appData = JSON.parse(window.diva.ui.data);
        this.appData['listingType'] = null;
        this.subjectData = this.appData.subjects;
        this.countriesData =this.appData.countries;
        this.subjectMountPoint = this.mountPoint.querySelector('#js-subject-selector');
        this.submitBtn = this.mountPoint.querySelector('.js-listing-submit');
        this.formApp = document.querySelector('#da-listing-form');

        this.thumbnailFileInput = document.querySelector('.js-thumbnail-image-input');
        this.thumbnailInput = document.querySelector('.js-listing-thumbnail');

        this.headerImageFileInput = document.querySelector('.js-header-image-input');
        this.headerImageInput = document.querySelector('.js-listing-header');

        this.featuredListingInput = document.querySelector('.js-is-featured-listing');
        this.featuredHeaderLabel = document.querySelector('.js-featured-header-label');
        this.featureFileUploaderLbl = "<br/>Max File Dimensions: height <strong>500px</strong> width <strong>400px</strong>. 1Mb";

        this.programService = new ListingService();
        this.initContentModal();
        this.run();
    }

    initContentModal() {
        this.deleteBtn = document.querySelector('.js-action-delete');
        const modalMount = document.querySelector('.js-content-modal');
        this.contentModal = new ContentModal(modalMount);

        if (this.deleteBtn) {
            this.deleteBtn.addEventListener('click', (e) => this.handleDeleteBtnClick(e));
        }
    }

    handleDeleteBtnClick(e) {
        const title = this.appData.listing.title;
        this.contentModal.title = "Delete Listing";
        this.contentModal.content = `
            <h4>Delete "${title}"</h4>
            <p>Are you sure? This action cannot be undone</p>
        `;
        this.contentModal.show(this.handleDeleteConfirmClick, this.handleDeleteConfirmClick);
    }

    handleDeleteConfirmClick = () => {
        let listingId = this.appData.listing.id;

        let csrfToken = Cookies.get('csrftoken');
        const customHeaders = {
            'X-CSRFToken': csrfToken,
            'Accept': 'application/json'
        };

        this.programService.deleteListing({'listing_id': listingId}, customHeaders).then((resp) => {
            if (resp.success) {
                this.contentModal.hide();
                console.log("Delete button closed");
                window.location.href = '/partner/listings'
            }
        });

        console.log("Delete button clicked");
    }

    handleDeleteCancelClick = () => {
        console.log("Cancel button clicked");
        this.contentModal.hide();
    }

    run():void {
        const _class = this;

        this.formApp = new Vue({
            components:{
                Multiselect,
                VueQuill,
                DatePicker,
                Checkbox,
                Checkboxes,
                Select,
                Option,
                ThumbnailInput,
                DropzoneFileUploader,
                FormUploader,
                ImagePreviewer
            },
            data() {
                const ListingType = {
                    JOB_INTERNSHIP_PAID: 'JOB_INTERNSHIP_PAID',
                    INTERN_ABROAD:'INTERN_ABROAD',
                    STUDY_ABROAD:'STUDY_ABROAD',
                    GRADUATE_SCHOOL: 'GRADUATE_SCHOOL',
                    UNDERGRAD_PROGRAMS: 'UNDERGRAD_PROGRAMS',
                    TEACH_ABROAD: 'TEACH_ABROAD',
                    VOLUNTEER_ABROAD: 'VOLUNTEER_ABROAD',
                    SCHOLARSHIPS: 'SCHOLARSHIPS'
                };

                return {
                    appData : _class.appData,
                    programType: ListingType.STUDY_ABROAD,
                    listingType: ListingType,
                    fieldOptions: _class.subjectData,
                    subjectsFieldValue:"",
                    programDuration: [],
                    requiredExams: [],
                    jobFunction: '',
                    subjectFormInput: null,
                    applicationDueDate: null,
                    goLiveDate: null,
                    mountPoint: this.$el,
                    form: null,
                    formApp: _class.formApp,
                    thumbUrl: null,
                    fileInput: _class.thumbnailFileInput,
                    thumbnailInput: _class.thumbnailInput,
                    headerImageFileInput: _class.headerImageFileInput,
                    headerImageInput: _class.headerImageInput,
                    currentHeader: null,
                    headerPreviewComponent: ImagePreviewer,
                    isFeaturedCheckbox: _class.featuredListingInput,
                    featuredImageLabel: _class.featuredHeaderLabel,
                    fileConstraints: {
                        maxHeight: 500,
                        maxWidth:400,
                        maxSize: 1048576
                    },
                    uploaderLabel: _class.featureFileUploaderLbl,
                    countries: _class.countriesData,
                    jobType: null,
                    jobWorkType: '',

                    quills: {
                        description: {
                            content: "",
                            config: {
                                placeholder: 'Description...',
                            }
                        },
                        qualifications: {
                            content: "",
                            config: {
                                placeholder: 'Program qualifications...',
                            }
                        },
                        education: {
                            content: '',
                            config: {
                                placeholder: 'Required education...',
                            }
                        },
                        languages: {
                            content: '',
                            config: {
                                placeholder: 'Required languages...',
                            }
                        },
                        applicationProcedure: {
                            content: '',
                            config: {
                                placeholder: 'How to apply...',
                            }
                        }
                    }
                };
            },
            computed: {
                descriptionLabel() {
                    if (this.programType == this.listingType.JOB_INTERNSHIP_PAID) {
                        return "Position Description";
                    }
                    else {
                        return "Program Description";
                    }
                },
                requiresExams() {
                    return ((this.programType == this.listingType.GRADUATE_SCHOOL) || (this.programType == this.listingType.UNDERGRAD_PROGRAMS));
                },
                minStartDate():string{
                    let dateStr:string = '';
                    const today:Date = new Date();
                    const year:number = today.getFullYear();
                    const month:number = today.getMonth();
                    const date:number = today.getDate();

                    dateStr = `${year}-${month}-${date}`;

                    return dateStr;
                }
            },
            created(){
                this.programType = _class.appData.listing_type;

                const job_input:HTMLInputElement = _class.mountPoint.querySelector('input[name="job_function"]');
                this.jobFunction = this.formatListFromElement(job_input, this.appData.volunteer_job_functions);

                const job_work_input:HTMLInputElement = _class.mountPoint.querySelector('input[name="job_work_type"]');
                this.jobWorkType = this.formatListFromElement(job_work_input, this.appData.job_work_types);

                if (this.thumbnailInput) {
                    this.thumbUrl = this.thumbnailInput.value;
                }

                if (this.headerImageInput) {
                    this.currentHeader = this.headerImageInput.value;
                }
            },
            mounted() {
                if(this.appData.edit) {
                    this.hydrate();
                }
                this.initFeaturedHeader();
            },
            methods: {
                formatListFromElement(el, masterList) {
                  return el.value.split(',').filter((item) => masterList.includes(item));
                },
                buildFormObject() {
                    this.form = {};
                    this.form['description'] = this.$el.querySelector('input[name="description"]');
                    this.form['qualifications'] = this.$el.querySelector('input[name="qualifications"]');

                    this.form['duration'] = this.$el.querySelector('input[name="duration"]');
                    this.form['subjects'] = this.$el.querySelector('input[name="subjects"]');
                    this.form['job'] = this.$el.querySelector('input[name="job_function"]');
                    this.form['exams'] = this.$el.querySelectorAll('input[name="required_exams"]');

                    this.form['education'] = this.$el.querySelector('input[name="required_education"]');
                    this.form['languages'] = this.$el.querySelector('input[name="required_languages"]');
                    this.form['procedure'] = this.$el.querySelector('input[name="application_procedure"]');
                    this.form['duedate'] = this.$el.querySelector('input[name="application_due_date"]');
                    this.form['golivedate'] = this.$el.querySelector('input[name="go_live_date"]');
                    this.form['job_type'] = this.$el.querySelector('input[name="job_type"]');
                    this.form['job_work_type'] = this.$el.querySelector('input[name="job_work_type"]');
                },

                initFeaturedHeader() {
                    const featuredCheckbox = this.$refs.isFeaturedCheckbox;
                    const headerImgLbl = this.$refs.isFeaturedLabel;

                    if (featuredCheckbox !== undefined) {
                        featuredCheckbox.addEventListener('change', (e:Event) => {
                            const checkbox = e.target as HTMLInputElement;
                            if (checkbox.checked) {
                                headerImgLbl.textContent = '*';
                            }
                            else {
                                headerImgLbl.textContent = '';
                            }
                        });
                    }
                },

                handleProgramChange(e) {
                    this.programType = e.target.value;
                },

                handleDescTextChange(editor, delta, source) {},

                handleQualificationTextChange(editor, delta, source) {},

                handleDueDateChange(e) {},

                handleFormSubmitted(e) {
                    //retrieve all relevant fields
                    e.preventDefault();
                    this.buildFormObject();
                    this.extract();

                    if (this.programType == 'SCHOLARSHIPS') Array.from(this.countries).forEach(country => {
                        const countryDiv = document.querySelector('#countries_multiselect');
                        const input = document.createElement('input');
                        input.type = 'hidden';
                        input.name = 'countries';
                        input.value = <string>country;
                        countryDiv.appendChild(input);
                    });

                    this.formApp.submit();
                },

                handleThumbnailDelete() {
                    if (_class.thumbnailInput) {
                        _class.thumbnailInput.value = '';
                    }
                },

                handleHeaderImageDelete() {
                    if (_class.headerImageInput) {
                        _class.headerImageInput.value = '';
                        this.currentHeader = null;
                    }
                },

                extract() {
                    if (this.$refs.descEditor) {
                        const descFieldValue = {
                            text: this.quills.description.content,
                            delta: this.$refs.descEditor.editor.getContents()
                        };
                        this.form['description'].value = JSON.stringify(descFieldValue);
                    }

                    if (this.$refs.qualsEditor) {
                        const qualsFieldValue = {
                            text: this.quills.qualifications.content,
                            delta: this.$refs.qualsEditor.editor.getContents()
                        };
                        this.form['qualifications'].value = JSON.stringify(qualsFieldValue);
                    }

                    if (this.$refs.eduEditor) {
                        const eduFieldValue = {
                            text: this.quills.education.content,
                            delta: this.$refs.eduEditor.editor.getContents()
                        };
                        this.form['education'].value = JSON.stringify(eduFieldValue);
                    }

                    if (this.$refs.langsEditor) {
                        const langsFieldValue = {
                            text: this.quills.languages.content,
                            delta: this.$refs.langsEditor.editor.getContents()
                        };
                        this.form['languages'].value = JSON.stringify(langsFieldValue);
                    }

                    if (this.$refs.procEditor) {
                        const procFieldValue = {
                            text: this.quills.applicationProcedure.content,
                            delta: this.$refs.procEditor.editor.getContents()
                        };
                        this.form['procedure'].value = JSON.stringify(procFieldValue);
                    }

                    this.form['job'].value = this.jobFunction;
                    this.form['job_work_type'].value = this.jobWorkType;
                    this.form['exams'].value = this.requiredExams;
                    this.form['duedate'].value = this.applicationDueDate;
                    this.form['golivedate'].value = this.goLiveDate;
                    this.form['duration'].value = this.programDuration;
                    this.form['subjects'].value = this.subjectsFieldValue;
                    this.form['subjects'].value = this.subjectsFieldValue;
                    this.form['subjects'].value = this.subjectsFieldValue;

                },
                hydrate() {
                    this.buildFormObject();

                    if (this.$refs.descEditor) {
                        const desc = this.getRichContent(this.form['description'].value);
                        this.$refs.descEditor.editor.setContents(desc);
                    }

                    if (this.$refs.qualsEditor) {
                        const quals = this.getRichContent(this.form['qualifications'].value);
                        this.$refs.qualsEditor.editor.setContents(quals);
                    }

                    if (this.$refs.eduEditor) {
                        const education = this.getRichContent(this.form['education'].value);
                        this.$refs.eduEditor.editor.setContents(education);
                    }

                    if (this.$refs.langsEditor) {
                        const langs = this.getRichContent(this.form['languages'].value);
                        this.$refs.langsEditor.editor.setContents(langs);
                    }

                    if (this.$refs.procEditor) {
                        const proc = this.getRichContent(this.form['procedure'].value);
                        this.$refs.procEditor.editor.setContents(proc);
                    }

                    this.jobFunction = this.formatListFromElement(
                      this.form['job'],
                      this.appData.volunteer_job_functions
                    );
                    this.jobWorkType = this.formatListFromElement(
                      this.form['job_work_type'],
                      this.appData.job_work_types
                    );
                    this.requiredExams = this.form['exams'].value;
                    this.applicationDueDate = this.form['duedate'].value;
                    this.goLiveDate = this.form['golivedate'].value;
                    this.programDuration = this.form['duration'].value.split(',');
                    this.subjectsFieldValue = this.form['subjects'].value.split(',');
                },
                getRichContent(value) {
                    let content:any;

                    try{
                        let obj = JSON.parse(value);
                        content = obj.delta;
                    }
                    catch(ex){
                        content = {
                            ops: [
                              { insert: value }
                            ]
                        };
                    }
                    return content;
                }
            }
        });
        this.formApp.$mount(this.mountPoint);
    }
}
