

































import Vue from 'vue';
import DropzoneUploader from './DropzoneUploader.vue';
import FormUploader from './FormUploader.vue'
import * as Cookies from 'js-cookie';
import AssetUploadService from '../../services/AssetUploadService';
import ConfirmModal from '../ConfirmModal.vue';

export default {
    name: 'single-file-uploader',
    props: [
        'assetService',
        'fileInfo',
        'uploader',
        'uploaderProps',
        'shouldDisplay'
    ],
    components: {
        DropzoneUploader,
        FormUploader,
        ConfirmModal
    },
    data() {
        return {
            file: null,
            fileUploadService: null,
            showModal: false,
            modalWindowContent: ''
        }
    },
    mounted() {

    },
    created() {
        this.fileUploadService = this.assetService;
        this.file = this.fileInfo;
    },
    computed: {
        hasResumeFile() {
            return this.file != null;
        },

        file_name() {
            return this.file ?  this.file.file_name : '';
        },

        file_path() {
            return this.file? this.file.file_path : '';
        }
    },
    methods: {
        handleFilesSelected(data) {
            const postData:FormData = new FormData();
            let csrfToken:string;
            let AssetService = this.fileUploadService;
            let service = new AssetService();

            csrfToken = Cookies.get('csrftoken');
            const customHeaders = {
                'X-CSRFToken': csrfToken,
                'Accept': 'application/json'
            };

            postData.append('resume', data[0]);
            postData.append('passport_form_id', 'PASSPORT_FORM_RESUME');
            service.uploadResume(postData, customHeaders).then((res) => {
                console.log(res);
                if (res.success) {
                    this.file = res.resume;
                    this.$refs.uploaderComponent.reset();
                    this.$emit('change', this.file);
                }
            });
        },

        handleRemoveClick(e) {
            e.preventDefault();
            this.modalWindowContent = "Are you sure you want to delete this file?";
            this.showModal = true;
        },

        handleCancelBtnClick(e) {
            this.showModal = false;
        },

        handleConfirmBtnClick(e) {
            let AssetService = this.fileUploadService;
            let service = new AssetService();
            this.showModal = false;
             let csrfToken:string;

            csrfToken = Cookies.get('csrftoken');

            const customHeaders = {
                'X-CSRFToken': csrfToken,
                'Accept': 'application/json'
            };

            service.deleteAssetFile({}, customHeaders).then((results) => {
                console.log("Asset Delete Request Processed");
                if (results.success) {
                    this.file = null;
                }

                this.$emit('change', this.file);
            });
            
        }


    }
}
