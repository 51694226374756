import HttpService from './HttpService';

export default class AssetUploadService extends HttpService {
    constructor() {
        super('/accounts/assets/');
    }

    uploadResume(data, customHeaders) {
        this.endpoint = '/dashboard/passport/resume';
        return this.uploadFile(data, customHeaders);
    }

    deleteAssetFile(data, customHeaders) {
        this.endpoint = '/passport/resume/delete';
        return this.post(data, customHeaders);
    }
}