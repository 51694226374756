export default class FormCollectionFilter {
    protected filtersForm:HTMLFormElement;
    protected selectFilters:HTMLSelectElement[];

    constructor(form:HTMLFormElement){
        this.filtersForm = form;
        //this.initialize();
    }

    initialize() {
        this.selectFilters = Array.from(this.filtersForm.querySelectorAll('.js-collection-sort-field'));
        this.selectFilters.forEach(filter => {
            let filterOptions:HTMLOptionsCollection = filter.options;
            
            let defaultOption = document.createElement('option');
            defaultOption.value = "default";
            defaultOption.textContent = "Default";
            
            filterOptions.add(defaultOption, 0);
            defaultOption.setAttribute('selected', 'selected');
        });
    }
}