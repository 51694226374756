

























import Vue from 'vue';
enum SearchMode {
    BASIC = 'BASIC',
    ADVANCED = 'ADV'
};
export default {
    name: 'listings-search-box',
    props: [
        'advancedFormActionUrl', 'basicFormActionUrl',
        'passedSearchQuery', 'title', 'searchType'
    ],
    data() {
        return {
            searchQ: '',
            activeSearchMode: SearchMode.BASIC,
            searchPlaceHolder: "Enter search term",
            submissionAttempt: false,
        }
    },
    created() {
        this.searchQ = this.passedSearchQuery;
    },
    computed: {
        invalidState() {
            return (this.searchQ == '') && this.submissionAttempt;
        },
        formActionUrl() {
            if (this.activeSearchMode == SearchMode.BASIC) {
                return this.basicFormActionUrl;
            } else {
                return this.advancedFormActionUrl;
            }
        }
    },
    methods: {
        handleItemSelected(e) {
            this.$emit('itemSelected', e.id);
        },

        handleAdvancedToggle(e) {
            e.preventDefault();
        },

        handleFormSubmit(e) {
            e.preventDefault();
            this.submissionAttempt = true;
            e.target.submit();
        }
    }
}

