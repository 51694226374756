











import Vue from 'vue';
import UserQuickEdit from './UserQuickEdit.vue';
import UserQuickView from './UserQuickView.vue';

export default {
    name: 'user-quick-view-panel',
    props: ['userdata', 'mode'],
    components: { UserQuickEdit, UserQuickView },
    data() {
        return {
            viewMode: 'edit'
        }
    },
    created() {
        this.viewMode = this.mode;
        this.current_user = this.userdata;
    },
    computed: {
        currentViewItem() {
            if (this.viewMode == 'edit') {
                return 'user-quick-edit';
            }
            else {
                return 'user-quick-view';
            }
            //return 'user-quick-edit';
        },
        activeProps() {
            return {
                user: this.current_user
            }
        }
    },
    methods: {
        handleEditProfile(e) {
            this.viewMode = 'edit';
        },

        handleCancelClick(e) {
            this.viewMode = 'display';
        },

        handleSaveButtonClick(e) {
            //replace this with vuex or a bus 
            this.$emit('userdataupdate', e);
        },

        setViewMode(v_mode) {
            this.viewMode = v_mode;
        },

        setUser(u) {
            this.current_user = u;
        }
    }

}
