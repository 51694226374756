/* Initialize Vuex Store */

import Vue from 'vue';
import Vuex from 'vuex'
import { state, mutations } from './registerStore'

Vue.use(Vuex);

export const registerStore = new Vuex.Store({
    state,
    mutations
});



