import Vue from 'vue';
import ThumbnailInput from './components/ThumbnailInput.vue';

export default class ThumbnailInputApp {
    protected fileInput:HTMLInputElement;
    protected mountPoint:HTMLElement;
    protected thumbnailSrc:string;
    protected thumbnailInput:HTMLInputElement;
    protected label:string;
    protected userData:any;

    constructor(initObj) {
        const sel = document.querySelector.bind(document); //save myself some typing
        this.fileInput = sel(initObj.inputSelector);
        this.mountPoint = sel(initObj.mountPoint);
        this.thumbnailSrc = initObj.thumbnailSrc;
        this.thumbnailInput = typeof(initObj.thumbnailInput) == 'string' ? sel(initObj.thumbnailInput): initObj.thumbnailInput;
        this.label = initObj.label || null;
        this.run();
    }

    run() {
        const _self = this;
        const thumbnailEd = new Vue({
            components: { ThumbnailInput },
            template: `
                <ThumbnailInput :src="thumbUrl" :file-input="fileInput" :label="label" @delete="handleDeleteClick" />
            `,
            data: {
                thumbUrl: _self.thumbnailSrc,
                fileInput: this.fileInput,
                label: this.label
            },
            methods: {
                handleDeleteClick() {
                    if (_self.thumbnailInput) {
                        _self.thumbnailInput.value = '';
                    }
                }
            }
        });

        thumbnailEd.$mount(this.mountPoint);
    }
}