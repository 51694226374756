import Vue from 'vue';

export default class InfinitePartnersSection {
  private readonly mountPoint: Element;
  private readonly sections: Object[];

  constructor(mountPoint: HTMLElement, sections: Object[]) {
    this.mountPoint = mountPoint;
    this.sections = sections;

    this.run();
  }

  run(): void {
    const _class = this;
    const footerElement = document.querySelector('footer');

    const infinitePartnersSection = new Vue({
      template: `
        <section>
          <div v-for="section in sections">
            <p>{{section.letter}}</p>
            <a v-for="partner in section.list" :href="partner.url">
              <div :style="partner.style">
                <img :src="partner.logo"/>
                <p>{{ partner.name }}</p>
              </div>
            </a>
          </div>
        </section>
      `,
      data() {
        return {
          sections: [],
          count: 1,
          loadMore: true,
        };
      },
      mounted() {
        this.load();
        this.handleScroll();
      },
      methods: {
        load() {
          if (this.sections.length < _class.sections.length) {
            this.sections = _class.sections.slice(0, this.count);
            this.count++;
          }
        },
        handleScroll() {
          window.onscroll = () => {
            const windowHeight = (<HTMLElement>document.querySelector('.da-app-view')).offsetHeight; // Workaround for mobile
            const scrollOffset = window.scrollY + window.innerHeight >= windowHeight - footerElement.offsetHeight;

            if (scrollOffset) {
              this.load();
            }
          };
        },
      },
    });
    infinitePartnersSection.$mount(this.mountPoint);
  }
}
