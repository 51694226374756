import Vue from 'vue';
import * as Cookies from 'js-cookie';
import ContentActivityBar from './components/ContentActivityBar.vue';
import ActivityService from './services/ActivityService';
import { ActivityType } from './types';


export default class ContentActivityManager {
    protected mountPoint:HTMLElement;
    protected activityService:ActivityService;
    protected csrfToken:string;
    protected appData;

    constructor(el:HTMLElement) {
        this.mountPoint = el;
        this.csrfToken = Cookies.get('csrftoken');
        this.activityService = new ActivityService();
        this.run();
    }

    run() {
        const divaUI:any = window.diva.ui.data ? JSON.parse(window.diva.ui.data) : {};
        const _class = this;

        const activityBar = new Vue({
            components: {ContentActivityBar},
            template: `
                <content-activity-bar
                    :articleid="articleId"
                    :facebook-id="fbAppId"
                    :bookmarked="is_bookmarked"
                    @bookmarkclick="handleBookmarkClick"
                />`,
            data: {
                articleId: divaUI.articleId,
                is_bookmarked: divaUI.is_bookmarked,
                fbAppId: divaUI.fb_app_id
            },
            methods: {
                dispatchActivity(payload):void {
                    const customHeaders = {
                        'X-CSRFToken': _class.csrfToken,
                        'Accept': 'application/json'
                    };

                    _class.activityService.postFormData(payload, customHeaders, true).catch(() => {
                        window.location.replace('/session/login/');
                    });
                },
                handleBookmarkClick(data) {
                    let payload = {
                        target: divaUI.articleId,
                        type: divaUI.activityType !== undefined ? divaUI.activityType : ActivityType.BOOKMARK_ARTICLE
                    };
                    this.dispatchActivity(payload);
                }
            }
        });
        activityBar.$mount(this.mountPoint);
    }
}
