


















import Multiselect from "vue-multiselect";

export default {
    name: 'job-work-type-filter',
    props: ['jobWorkTypes', 'label'],
    components: { Multiselect },
    data() {
        return {
            searchJobWorkTypes: [],
            placeholder: this.label ? 'Select option' : 'Select Work Type',
        }
    },
    mounted() {
      try {
        const url = new URL(window.location.href);
        const params = url.searchParams.getAll('LST_JOB_WORK_TYP');
        params.forEach(param => {
          if (this.jobWorkTypes.includes(param)) {
            this.searchJobWorkTypes.push(param);
          }
        });
      } catch {}
    },
}

