import Vue from 'vue';
import ListingService from './services/ListingService';
import OpportunitiesSearchBox from './components/OpportunitiesSearchBox.vue';

export default class OpportunitiesPage {
    protected searchElement:HTMLFormElement;
    protected acSearch;
    protected app:any;
    protected appData:any;

    constructor(el:HTMLElement) {
        this.searchElement = el as HTMLFormElement;
        this.appData = JSON.parse(window.diva.ui.data);
        this.run();
    }

    handleItemSelected(e) {
        let listing_url = `/listings/${e.id}`;
        window.location.href = listing_url;
    }

    run() {
        const _class = this;

        // listings search
        if (this.searchElement) {
            this.acSearch = new Vue({
                el: this.searchElement,
                template:`
                    <opportunities-search-box
                        :job-functions="jobFunctions"
                        :programs-abroad-types="programsAbroad"
                        :program-listing-types="programListings"
                        :program-locations="programLocations"
                        :study-terms="studyTerms"
                        :subjects="subjects"
                    ></opportunities-search-box>
                `,
                components: { OpportunitiesSearchBox },
                data() {
                    return {
                        programListings: _class.appData.listing_types,
                        programsAbroad: _class.appData.programs_abroad,
                        programLocations: _class.appData.listing_locations,
                        studyTerms: _class.appData.study_terms,
                        jobFunctions: _class.appData.job_functions,
                        subjects: _class.appData.subjects,
                        job: _class.appData.study_terms
                    }
                },
                methods: {
                    handleItemSelected(val) {
                        let listing_url = `/listings/${val}`;
                        window.location.href = listing_url;
                    }
                }
            });
        }
    }
}
