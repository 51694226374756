import Vue from 'vue';
import * as Cookies from 'js-cookie';
import ImportService from './services/ImportService';
import Modal from './components/ModalExtended.vue';
import ImportToolbar from './components/ImportToolbar.vue';

export default class StudentImportManager {
  private readonly mountPoint: Element;
  private readonly csrfToken: string;
  private currentPage: number;
  private importService: ImportService;

  constructor(el: HTMLElement, currentPage: number) {
    this.mountPoint = el instanceof Element ? el : document.querySelector(el);
    this.csrfToken = Cookies.get('csrftoken');
    this.importService = new ImportService();
    this.currentPage = currentPage;
    this.run().then();
  }

  async run(): Promise<void> {
    const _class = this;

    const customHeaders = {
      'Accept': 'application/json',
      'X-CSRFToken': _class.csrfToken,
      'page': _class.currentPage,
    };

    const data = await _class.importService.get({}, customHeaders);

    const studentImportManager = new Vue({
      components: {Modal, ImportToolbar},
      template: `
        <div>
          <div v-if="success_message !== ''" class="success-text-section">
            <p>{{success_message}}</p>
          </div>
          <import-toolbar
            ref="importToolbar"
            :selected-prop="selected"
            :csrf-token-prop="csrfToken"
            :current-page-prop="currentPage"
            @toggleBulkModal="toggleBulkModal"
            @toggleStudentModal="toggleStudentModal"
            @handleDeleteStudents="handleDeleteStudents"
            @handleSelectAll="handleSelectAll"
            @handleSearch="handleSearch"
            @handleResendMail="handleResendMail"/>
          <p v-if="isMaximumNumberOfLicensesReached" class="license-warning-message">
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            You have reached the maximum number of sublicenses ({{licenseData['number_of_sublicenses']}}).
            <b v-if="isThereStudentsWithoutLicense">
              &nbsp;Currently, {{licenseData['number_of_missing_licenses']}} out of {{pageData['number_of_students']}}
              your students don't have access to the license.
            </b>
          </p>
          <section class="import-table">
            <table>
              <thead>
              <tr>
                <th @click="handleSelectAll">
                  <i :style="selectIcon.style" :class="selectIcon.class" aria-hidden="true"></i>
                </th>
                <th @click="sortStudents(sortObj.first_name)">
                  First Name <i :class="sortObj.first_name.icon" aria-hidden="true"
                                :style="sortObj.first_name.style"></i>
                </th>
                <th @click="sortStudents(sortObj.last_name)">
                  Last Name <i :class="sortObj.last_name.icon" aria-hidden="true" :style="sortObj.last_name.style"></i>
                </th>
                <th @click="sortStudents(sortObj.email)">
                  Email <i :class="sortObj.email.icon" aria-hidden="true" :style="sortObj.email.style"></i>
                </th>
                <th @click="sortStudents(sortObj.date_joined)">
                  Added <i :class="sortObj.date_joined.icon" aria-hidden="true" :style="sortObj.date_joined.style"></i>
                </th>
                <th @click="sortStudents(sortObj.license)">
                  License Access <i :class="sortObj.license.icon" aria-hidden="true" :style="sortObj.license.style"></i>
                </th>
                <th @click="sortStudents(sortObj.account_validated)">
                  Validated <i :class="sortObj.account_validated.icon" aria-hidden="true"
                               :style="sortObj.account_validated.style"></i>
                </th>
                <th></th>
                <th></th>
              </tr>
              </thead>
              <tbody v-if="students.length === 0">
              <tr>
                <td colspan="8">
                  <div class="no-imported-students">
                    <div class="icon-wrapper"><i class="fa fa-user-plus" aria-hidden="true"></i></div>
                    <h3>No students added yet</h3>
                    <p>
                      <a @click="toggleBulkModal">Bulk import</a> multiple students by uploading a spreadsheet
                      or <a @click="toggleStudentModal">manually add</a> one student at a time.
                    </p>
                  </div>
                </td>
              </tr>
              </tbody>
              <tbody v-else>
              <tr v-for="student in students" :class="{ 'selected' : selected.includes(student)}">
                <td class="clickable" v-if="selected.includes(student)" @click="handleUnselect(student)">
                  <i class="fa fa-check-square" aria-hidden="true"></i>
                </td>
                <td class="clickable" v-else @click="handleSelect(student)">
                  <i class="fa fa-square-o" aria-hidden="true"></i>
                </td>
                <td>{{student.first_name}}</td>
                <td>{{student.last_name}}</td>
                <td>{{student.email}}</td>
                <td>{{student.added}}</td>
                <td v-if="student.has_license"><i class="fa fa-check" aria-hidden="true"></i> Yes</td>
                <td v-else><i class="fa fa-times" aria-hidden="true"></i> No</td>
                <td v-if="student.is_validated"><i class="fa fa-check" aria-hidden="true"></i> Yes</td>
                <td v-else>
                  <i class="fa fa-times" aria-hidden="true"></i> No (
                  <span class="clickable" @click="handleResendMail(student.id)" style="color: #e27707;">
                    <i class="fa fa-envelope"></i> Resend Email
                  </span>)
                </td>
                <td class="clickable" @click="handleDeleteStudents(student)">
                 <i class="fa fa-user-times" style="color: #990b0b;"></i> Remove Access
                </td>
              </tr>
              </tbody>
            </table>
          </section>
          <div class="pagination" v-if="students.length > 0">
            <ul>
              <li class="back" :class="{ 'disabled' : !pageData['has_previous_page']}">
                <a v-if="pageData['has_previous_page']" :href="currentUrl + '?page=' + pageData['previous_page']">
                  <i class="fa fa-chevron-left"></i> Back
                </a>
                <span v-else><i class="fa fa-chevron-left"></i> Back</span>
              </li>
              <li
                v-for="page in [...Array(pageData['last_page'] + 1).keys()]"
                v-if="page !== 0"
                class="item"
                :class="{ 'active' : page === pageData['current_page']}">
                <a :href="currentUrl + '?page=' + page">{{ page }}</a>
              </li>
              <li class="back" :class="{ 'disabled' : !pageData['has_next_page']}">
                <a v-if="pageData['has_next_page']" :href="currentUrl + '?page=' + pageData['next_page']">
                  Next <i class="fa fa-chevron-right"></i>
                </a>
                <span v-else>Next <i class="fa fa-chevron-right"></i></span>
              </li>
            </ul>
            <p>Viewing {{ pageData['index_of_first_student'] }}-{{ pageData['index_of_last_student'] }}
              of {{ pageData['number_of_students'] }}</p>
          </div>
          <modal :isActiveProp="isStudentModalActive" @close="closeStudentModal">
            <div class="manual-import-modal">
              <div v-if="error_message !== ''" class="error-text-section">
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                <p v-html="error_message">{{error_message}}</p>
              </div>
              <h3 v-if="student.id === undefined">Add Student</h3>
              <h3 v-else>Update Student</h3>
              <form class="da-forms">
                <div class="modal-item-group">
                  <div class="da-form-formItem">
                    <label>First Name</label>
                    <input type="text" class="da-form-formItem" v-model="student.first_name"/>
                  </div>
                  <div class="da-form-formItem">
                    <label>Last Name</label>
                    <input type="text" class="da-form-formItem" v-model="student.last_name"/>
                  </div>
                </div>
                <div class="modal-item-group" v-if="student.id === undefined">
                  <div class="da-form-formItem">
                    <label>Email</label>
                    <input type="text" class="da-form-formItem" v-model="student.email"/>
                  </div>
                </div>
                <div class="modal-item-group">
                  <input @click="(e) => handleManualImport(e, true)" type="submit" value="Import & Send Invite"
                         class="da-components-button da-btn-solid" style="flex: 1;">
                  <input @click="(e) => handleManualImport(e, false)" type="submit" value="Import Without Inviting"
                         class="da-components-button da-btn-standard" style="flex: 1;">
                  <input @click="handlManualCancel" type="submit" value="Cancel Upload"
                         class="da-components-button da-btn-standard" style="flex: 1;">
                </div>
              </form>
            </div>
          </modal>
          <modal :isActiveProp="isBulkModalActive" @close="closeBulkModal">
            <div class="bulk-import-modal">
              <div v-if="error_message !== ''" class="error-text-section">
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                <p v-html="error_message">{{error_message}}</p>
              </div>
              <h3>Import Students</h3>
              <form class="da-forms">
                <div class="da-form-formItem" @drop.prevent="handleFileUpload" @dragover.prevent>
                  <label for="file-input" class="label-file-wrapper">
                    <div class="content">
                      <div class="img">
                        +
                      </div>
                      <div class="body">
                        <h4>Drag and drop files or browse from computer</h4>
                        <p>Allowed formats: .csv, .xls, .xlsx</p>
                      </div>
                    </div>
                  </label>
                  <input
                    ref="fileUploadInput"
                    @click="resetFileUpload"
                    @change="handleFileUpload"
                    id="file-input"
                    type="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                </div>
                <p v-if="files.length === 0" class="get-started-text">
                  <i class="fa fa-cloud-upload" aria-hidden="true"></i> Upload a file to get started.
                </p>
                <div v-else class="files-for-import">
                  <div v-for="file in files" class="file-wrapper">
                    <i class="fa fa-file-excel-o file-icon" aria-hidden="true"></i>
                    <div>
                      <p>{{file.name}} <i class="fa fa-check-circle" aria-hidden="true"></i></p>
                      <div class="progress-bar"></div>
                    </div>
                    <i @click="handleDeleteFileFromList(file)" class="fa fa-trash delete-icon" aria-hidden="true"></i>
                  </div>
                  <div class="button-container">
                    <input @click="(e) => handleImport(e, true)" type="submit" value="Import & Send Invites"
                           class="da-components-button da-btn-solid">
                    <input @click="(e) => handleImport(e, false)" type="submit" value="Import Without Inviting"
                           class="da-components-button da-btn-standard">
                    <input @click="handleBulkCancel" type="submit" value="Cancel Upload"
                           class="da-components-button da-btn-standard">
                  </div>
                </div>
              </form>
            </div>
          </modal>
          <modal :isActiveProp="isConfirmModalActive" @close="closeConfirmModal" styleProp="width: 300px;">
            <div class="bulk-confirm-modal">
              <h3>Import Summary</h3>
              <p v-html="confirmMessage">{{confirmMessage}}</p>
              <div class="button-container">
                <input @click="(e) => handleImport(e, confirmSendInvites, true)" type="submit" value="Continue"
                       class="da-components-button da-btn-solid">
                <input @click="closeConfirmModal" type="submit" value="Cancel"
                       class="da-components-button da-btn-standard">
              </div>
            </div>
          </modal>
          <modal :isActiveProp="isWarningModalActive" @close="closeWarningModal" styleProp="width: 50%; padding: 0;">
            <p class="license-warning-modal">
              <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
              You have reached the maximum number of sublicenses ({{licenseData['number_of_sublicenses']}}).
            </p>
          </modal>
          <modal :isActiveProp="isDeleteModalActive" @close="closeDeleteModal">
            <div class="manual-import-modal">
              <h3>Please confirm</h3>
              <p>
                Are you sure you want to remove access for the following student(s)
                <span v-html="studentsForDeletionFormatted"></span>?
              </p>
              <div class="modal-item-group" style="padding: 10px 0;">
                <input type="submit"
                       value="Cancel"
                       class="da-components-button da-btn-standard"
                       @click="closeDeleteModal">
                <input type="submit"
                       value="Remove Access"
                       class="da-components-button da-components-button--critical"
                       @click="doDeleteStudents">
              </div>
            </div>
          </modal>
        </div>
      `,
      data() {
        return {
          csrfToken: _class.csrfToken,
          currentPage: _class.currentPage,
          student: {
            'id': undefined,
            'first_name': '',
            'last_name': '',
            'email': '',
          },
          students: data['students'],
          pageData: data['page_data'],
          licenseData: data['license_data'],
          selected: [],
          isBulkModalActive: false,
          isStudentModalActive: false,
          isConfirmModalActive: false,
          isWarningModalActive: false,
          confirmSendInvites: false,
          confirmMessage: '',
          error_message: '',
          success_message: '',
          files: [],
          sortObj: {
            param: '',
            first_name: {prop: 'first_name', dir: true, icon: 'fa fa-sort-amount-asc', style: {color: '#4a4a4a'}},
            last_name: {prop: 'last_name', dir: true, icon: 'fa fa-sort-amount-asc', style: {color: '#4a4a4a'}},
            email: {prop: 'email', dir: true, icon: 'fa fa-sort-amount-asc', style: {color: '#4a4a4a'}},
            date_joined: {prop: 'date_joined', dir: true, icon: 'fa fa-sort-amount-asc', style: {color: '#4a4a4a'}},
            license: {prop: 'license', dir: true, icon: 'fa fa-sort-amount-asc', style: {color: '#4a4a4a'}},
            account_validated: {
              prop: 'account_validated',
              dir: true,
              icon: 'fa fa-sort-amount-asc',
              style: {color: '#4a4a4a'}
            },
          },
          studentsForDeletion: [],
          isDeleteModalActive: false,
        };
      },
      computed: {
        currentUrl() {
          return window.location.pathname;
        },
        selectIcon() {
          return this.selected.length === 0
            ? {class: 'fa fa-square-o', style: {}}
            : this.selected.length === this.students.length
              ? {class: 'fa fa-check-square', style: {color: '#80B428'}}
              : {class: 'fa fa-square', style: {color: '#80B428'}};
        },
        isMaximumNumberOfLicensesReached() {
          return this.licenseData['number_of_sublicenses'] <= this.pageData['number_of_students'];
        },
        isThereStudentsWithoutLicense() {
          return this.licenseData['number_of_missing_licenses'] > 0;
        },
        studentsForDeletionFormatted() {
          return this.studentsForDeletion.map(student => ` <b>${student.first_name} ${student.last_name}</b>`).toString();
        },
      },
      methods: {
        async sortStudents(sortObj) {
          let {param} = this.sortObj;
          param = param
            .replace(`-${sortObj.prop},`, '')
            .replace(`${sortObj.prop},`, '');
          const dir = sortObj.dir ? '' : '-';
          param = `${dir}${sortObj.prop},` + param;

          this.sortObj[sortObj.prop] = {
            prop: sortObj.prop,
            dir: !sortObj.dir,
            icon: `fa fa-sort-amount-${sortObj.dir ? 'asc' : 'desc'}`,
            style: {color: '#e27707'}
          };
          this.sortObj.param = param;

          const data = await _class.importService.get({'sort': param}, customHeaders);

          this.students = data['students'];
          this.pageData = data['page_data'];
          this.licenseData = data['license_data'];
        },
        async handleSearch(searchTerm) {
          this.cleanSort();

          const data = await _class.importService.get({'q': searchTerm}, customHeaders);

          this.students = data['students'];
          this.pageData = data['page_data'];
          this.licenseData = data['license_data'];
        },
        setSuccessMessage(message) {
          this.success_message = message;
          setTimeout(() => this.success_message = '', 3000);
        },
        cleanSort() {
          this.sortObj = {
            param: '',
            first_name: {prop: 'first_name', dir: true, icon: 'fa fa-sort-amount-asc', style: {color: '#4a4a4a'}},
            last_name: {prop: 'last_name', dir: true, icon: 'fa fa-sort-amount-asc', style: {color: '#4a4a4a'}},
            email: {prop: 'email', dir: true, icon: 'fa fa-sort-amount-asc', style: {color: '#4a4a4a'}},
            date_joined: {prop: 'date_joined', dir: true, icon: 'fa fa-sort-amount-asc', style: {color: '#4a4a4a'}},
            license: {prop: 'license', dir: true, icon: 'fa fa-sort-amount-asc', style: {color: '#4a4a4a'}},
            account_validated: {
              prop: 'account_validated',
              dir: true,
              icon: 'fa fa-sort-amount-asc',
              style: {color: '#4a4a4a'}
            },
          };
        },
        async cleanAndRefreshData() {
          const data = await _class.importService.get({}, customHeaders);

          this.students = data['students'];
          this.pageData = data['page_data'];
          this.licenseData = data['license_data'];

          if (this.students.length === 0 && this.pageData['current_page'] > 1) {
            window.location.replace(`${this.currentUrl}?page=1`);
          }

          this.student = {
            'id': undefined,
            'first_name': '',
            'last_name': '',
            'email': '',
          };

          this.selected = [];
          this.error_message = '';
          this.files = [];

          this.cleanSort();
        },
        toggleBulkModal(): void {
          if (this.isMaximumNumberOfLicensesReached) {
            this.isWarningModalActive = true;
            return;
          }

          this.isBulkModalActive = !this.isBulkModalActive;
        },
        toggleStudentModal(): void {
          if (this.isMaximumNumberOfLicensesReached) {
            this.isWarningModalActive = true;
            return;
          }

          this.isStudentModalActive = !this.isStudentModalActive;
        },
        closeStudentModal(): void {
          this.toggleStudentModal();
          this.cleanAndRefreshData();
        },
        closeBulkModal(): void {
          this.toggleBulkModal();
          this.cleanAndRefreshData();
        },
        closeConfirmModal(): void {
          this.isConfirmModalActive = false;
        },
        closeWarningModal(): void {
          this.isWarningModalActive = false;
        },
        closeDeleteModal(): void {
          this.isDeleteModalActive = false;
          this.studentsForDeletion = [];
        },
        handleSelect(student) {
          this.selected.push(student);
        },
        handleUnselect(student) {
          this.selected = this.selected.filter(s => s.id !== student.id);
        },
        handleSelectAll(deselect = false) {
          if (this.students.length === this.selected.length || !deselect) {
            this.selected = [];
          } else {
            this.selected = this.students;
          }
        },
        resetFileUpload() {
          this.$refs.fileUploadInput.value = '';
        },
        handleFileUpload(e) {
          const files = e.target.files || e.dataTransfer.files;
          if (!files.length)
            return;
          const file = files[0];
          if (file && (file.name.endsWith('.csv') || file.name.endsWith('.xls') || file.name.endsWith('.xlsx'))) {
            this.error_message = '';
            this.files.push(file);
          } else {
            this.error_message = 'That file type is not supported. Allowed formats: .csv, .xls, .xlsx';
          }
        },
        handleDeleteFileFromList(file) {
          this.files = this.files.filter(f => f !== file);
        },
        handleBulkCancel(e) {
          e.preventDefault();
          this.closeBulkModal();
        },
        handlManualCancel(e) {
          e.preventDefault();
          this.closeStudentModal();
        },
        async handleImport(e, sendInvites, confirm = false) {
          e.preventDefault();

          const response = await _class.importService.postFormData({
            ...this.files,
            send_invites: sendInvites,
            confirm: confirm,
          }, customHeaders);

          if (response.status === 'error') {
            const replaceAll = (str, find, replace) => str.replace(new RegExp(find, 'g'), replace);
            let error_message = response.message;
            error_message = replaceAll(error_message, '{NEW_LINE}', '<br/>');
            error_message = replaceAll(error_message, 'FILE:', '<b>FILE</b>:');
            error_message = replaceAll(error_message, 'ERROR:', '<b>ERROR</b>:');
            this.error_message = `${error_message}<br/>(Valid rows: ${response.valid} of ${response.total})`;
            this.report = undefined;
          } else if (response.status === 'file_error') {
            const {files} = response;
            const filesHtml = files.map((file) => '<b><u>' + file + '</u></b>');
            const filesInfo = files.length === 1
              ? `File ${filesHtml[0]} is`
              : `Files ${filesHtml.join(', ')} are`;
            const link = `<a target="_blank" href="https://www.diversityabroad.com/articles/abroad360-user-bulk-upload-support">help documentation</a>.`;
            this.error_message = `${filesInfo} not formatted properly. For help with formatting, please see our ${link}`;
          } else if (response.status === 'inspect') {
            this.error_message = '';
            this.confirmSendInvites = sendInvites;
            const {valid, invalid, total, duplicate} = response;

            const toUlList = (item) => `- ${item}<br/>`;

            const duplicateText = duplicate.length > 0
              ? `<br/>System detected ${duplicate.length} duplicate email(s): <br>${duplicate.map(toUlList).join('')}</br>`
              : '';
            if (parseInt(invalid) === 0) {
              this.confirmMessage = `${total} entries detected. 0 errors.${duplicateText} Continue?`;
            } else {
              this.confirmMessage = `${total} total entries. ${valid} valid entries. We are unable to `
                + `import ${invalid} invalid entries due to improper formatting.${duplicateText} `
                + `Would you like to continue with the valid entries?`;
            }
            this.isConfirmModalActive = true;
          } else if (response.status === 'success') {
            this.closeConfirmModal();
            this.closeBulkModal();
            this.setSuccessMessage(`Imported ${response.total} student(s).`);
          }
        },
        async handleManualImport(e, sendInvite) {
          e.preventDefault();

          const student = Object.assign({}, this.student);
          let error_message = '';

          if (!student.first_name || student.first_name.length === 0) {
            error_message += 'First name is missing. ';
          }

          if (!student.last_name || student.last_name.length === 0) {
            error_message += 'Last name is missing. ';
          }

          if (student.id === undefined && (!student.email || !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(student.email)))) {
            error_message += 'Bad email format.';
          }

          if (error_message === '') {
            this.error_message = '';

            const data = {'student': JSON.stringify(this.student), 'import_type': 'MANUAL', send_invite: sendInvite};

            const response = await _class.importService.postFormData(data, customHeaders);

            if (response.status === 'error') {
              this.error_message = response.message;
              this.report = undefined;
            } else if (response.status === 'success') {
              this.cleanAndRefreshData();
              this.toggleStudentModal();
              this.setSuccessMessage(`${student.id === undefined ? 'Added' : 'Updated'} ${student.email}.`);
            }
          } else {
            this.error_message = error_message;
          }
        },
        handleDeleteStudents(student) {
          this.studentsForDeletion = student ? [student] : this.selected;
          this.isDeleteModalActive = true;
        },
        async doDeleteStudents() {
          const data = {'ids': this.studentsForDeletion.map(s => s.id)};
          this.closeDeleteModal();

          const response = await _class.importService.delete(data, customHeaders);

          if (response.status === 'error') {
            this.error_message = response.message;
            this.report = undefined;
          } else if (response.status === 'success') {
            this.cleanAndRefreshData();
            this.setSuccessMessage(`Removed access for ${response.total_number} student(s).`);
          }
        },
        async handleResendMail(userId = undefined) {
          const data = {
            'ids': userId ? [userId] : this.selected.map(s => s.id),
          };

          await _class.importService.putFormData(data, customHeaders);

          this.setSuccessMessage('Mail has been resent.');

          this.cleanAndRefreshData();
        },
      },
    });
    studentImportManager.$mount(this.mountPoint);
  }
}
