//://stackoverflow.com/questions/14432165/uncaught-syntaxerror-unexpected-token-with-json-parse
const sanitize = (s:string) => s.replace(/\\n/g, "\\n")  
.replace(/\\'/g, "\\'")
.replace(/\\"/g, '\\"')
.replace(/\\&/g, "\\&")
.replace(/\\r/g, "\\r")
.replace(/\\t/g, "\\t")
.replace(/\\b/g, "\\b")
.replace(/\\f/g, "\\f")
.replace(/[\u0000-\u0019]+/g,"");


//Based on  https://gomakethings.com/how-to-truncate-text-with-vanilla-javascript/

const truncate = (src:string, limit:number, after?:string) => {
    let content:string;
    const afterLen:number = after ? after.length : 0;
    const actualContentLen:number = limit - afterLen;
    // Make sure an element and number of items to truncate is provided
    if (!src || !limit) return;
    // Get the inner content if element
    if (typeof(src) != "string") {
      throw new Error("Unknown error. Source variable should be a string.");
    }
    
    if ((src.length <= actualContentLen) || (actualContentLen <= 0)){
      return src;
  
    }
    else {
      // Convert the content into an array of words
      // Remove any words above the limit
      const tokens = src.split(' ');
      let acc = tokens[0];
      tokens.every((curr, ind) => {
        if (!ind) return true; //don't process first array element 0
        const s = `${acc} ${curr}`;
        if (s.length < actualContentLen) {
          acc = s;
          return !!s;
        }
        return false;
      });
      content = acc;
    }
    
    // Convert the array of words back into a string
    // If there's content to add after it, add it
    content = content + (after ? after : '');
  
    return content;
  };
  

export { sanitize, truncate };