





























import { truncate } from '../utils/StringUtils';

export default {
    name: 'content-activity-bar',
    props: ['article_id','title','url', 'bookmarked', 'facebookId'],
    data() {
        return {
            fb_share_url: '#',
            linkedin_share_url: '#',
            twitter_share_url:'#',
            bookmark_action: '#',
            is_bookmarked: false,
            content_title: '',
            content_summary: '',
            content_url: ''
        };
    },
    computed: {
        bookmark_prompt() {
            if (this.is_bookmarked) {
                return "You've bookmarked this article";
            }
            else {
                return "Bookmark this article";
            }
        }
    },
    created() {
        this.is_bookmarked = this.bookmarked;
        this.extractContent();
    },
    methods:{
        extractContent() {
            const titleTag = document.querySelector('title');
            const descMetaTag = document.querySelector('meta[name="description"]');

            this.content_title = titleTag ? encodeURI(titleTag.textContent) : '';

            if (descMetaTag) {
                const summary = truncate(descMetaTag.getAttribute('content'), 200, '...');
                this.content_summary = encodeURI(summary);
            } else {
                this.content_summary = '';
            }
              
            this.content_url = window.location.href;

        },
        handleFBShareClick(e) {
            e.preventDefault();
            const shareLink = 'https://www.facebook.com/dialog/share';
            const content = `${shareLink}?app_id=${this.facebookId}&href=${this.content_url}&display=popup&quote=${this.content_title}`;
            const facebookWindow = window.open(content, 'Share on Facebook', 'height=350,width=600');
            if(facebookWindow.focus) { facebookWindow.focus(); }
            return false;
        },
        handleLinkedInShareClick(e) {
            e.preventDefault();
            const shareLink = 'https://www.linkedin.com/shareArticle';
            const content = `${shareLink}?mini=true&summary=${this.content_summary}&url=${this.content_url}`;
            const linkedInWindow = window.open(content, 'Share on LinkedIn', 'height=350,width=600');
            if (linkedInWindow) { linkedInWindow.focus(); }
            return false;
        },
        handleTwitterShareClick(e) {
            e.preventDefault();
            const shareLink = 'https://twitter.com/intent/tweet';
            const content = `${shareLink}?text=${this.content_summary}&url=${this.content_url}`;
            const twitterWindow = window.open(content, 'Share on twitter', 'height=350,width=600');
            if(twitterWindow.focus) { twitterWindow.focus(); }
            return false;
        },
        handleBookmarkClick(e) {
            this.$emit('bookmarkclick');
            this.is_bookmarked = this.is_bookmarked ? false : true;
        }
    }
};
