<template>
  <header>
    <section class="toolbar">
      <div><h1>Your Students</h1></div>
      <div v-if="selected.length === 0" class="action-buttons-container">
        <div @click="toggleBulkModal" class="da-components-button da-btn-solid bulk-button">
          <i class="fa fa-upload" aria-hidden="true"></i> Bulk Import
        </div>
        <div @click="toggleStudentModal" class="da-components-button da-btn-standard bulk-button">
          <i class="fa fa-plus" aria-hidden="true"></i> Manual Add
        </div>
        <div class="da-components-button da-btn-standard bulk-button bulk-menu"
             :class="{ 'active' : isExportMenuActive}">
          <header @click="toggleExportMenu">
            <i class="fa fa-download" aria-hidden="true"></i>
            <p> Export </p>
            <i class="fa fa-caret-down" aria-hidden="true"></i>
          </header>
          <ul :class="{ 'active' : isExportMenuActive}">
            <li @click="handleExport('csv')">CSV file</li>
            <li @click="handleExport('xlsx')">XLSX file</li>
          </ul>
        </div>
      </div>
      <div v-else class="action-buttons-container">
        <div @click="handleDeleteStudents(undefined)" class="da-components-button da-btn-standard bulk-button">
          <i class="fa fa-user-times"></i> Remove Access
        </div>
        <div @click="handleResendMail(undefined)" class="da-components-button da-btn-standard bulk-button">
          <i class="fa fa-envelope" aria-hidden="true"></i> Resend Email
        </div>
        <div class="da-components-button da-btn-standard bulk-button bulk-menu"
             :class="{ 'active' : isExportMenuActive}">
          <header @click="toggleExportMenu">
            <i class="fa fa-download" aria-hidden="true"></i>
            <p> Export </p>
            <i class="fa fa-caret-down" aria-hidden="true"></i>
          </header>
          <ul :class="{ 'active' : isExportMenuActive}">
            <li @click="handleExport('csv')">CSV file</li>
            <li @click="handleExport()">XLSX file</li>
          </ul>
        </div>
        <div
          @click="handleSelectAll(false)"
          class="da-components-button da-btn-standard bulk-button"
          style="border: none; width: fit-content;">
          Deselect All
        </div>
      </div>
    </section>
    <div v-if="selected.length === 0" class="text-input-container">
      <i class="fa fa-search" aria-hidden="true"></i>
      <input @keyup="handleSearch" name="q" type="text" class="js-global-search-input" placeholder="Search"
             autocomplete="off" v-model="searchTerm"/>
    </div>
  </header>
</template>

<script>
  import ExportService from "../services/ExportService";
  const exportService = new ExportService();
  export default {
    name: 'import-toolbar',
    props: ["selectedProp", "csrfTokenProp", "currentPageProp"],
    data() {
      return {
        selected: [],
        csrfToken: '',
        currentPage: 1,
        isExportMenuActive: false,
        searchTerm: '',
      };
    },
    watch: {
      selectedProp: {
        immediate: true,
        handler(value) {
          this.selected = Object.assign([], value);
        },
      },
      csrfTokenProp: {
        immediate: true,
        handler(value) {
          this.csrfToken = Object.assign([], value);
        },
      },
      currentPageProp: {
        immediate: true,
        handler(value) {
          this.currentPage = Object.assign([], value);
        },
      },
    },
    methods: {
      toggleExportMenu() {
        this.isExportMenuActive = !this.isExportMenuActive;
      },
      async handleExport(type = 'xlsx') {
        const selected = this.selected.length > 0 ? this.selected : [];
        const students = selected.map(s => s.id).join(',');
        const studentsParam = students ? students : '[]';
        const customHeaders = {
          'Accept': 'application/json',
          'X-CSRFToken': this.csrfToken,
          'page': this.currentPage,
        };
        await exportService.downloadFile(type, studentsParam, customHeaders, `export.${type}`)
      },
      toggleBulkModal() {
        this.$emit("toggleBulkModal");
      },
      toggleStudentModal() {
        this.$emit("toggleStudentModal");
      },
      handleDeleteStudents(param) {
        this.$emit("handleDeleteStudents", param);
      },
      handleSelectAll(param) {
        this.$emit("handleSelectAll", param);
      },
      handleSearch() {
        this.$emit("handleSearch", this.searchTerm);
      },
      handleResendMail() {
        this.$emit("handleResendMail");
      },
    },
  };
</script>
