import Vue from 'vue';
import * as Cookies from 'js-cookie';
import DatePicker from './vendor/fish-ui/src/components/DatePicker.vue';
import LicenseService from './services/LicenseService';

export default class StaffLicenseManager {
  protected csrfToken: string;
  protected mountPoint: HTMLElement;
  protected appData: any;
  protected service: LicenseService;

  constructor(el: HTMLElement, appData: any) {
    this.csrfToken = Cookies.get('csrftoken');
    this.mountPoint = el instanceof Element ? el : document.querySelector(el);
    this.appData = appData;
    this.service = new LicenseService();
    this.run().then(() => {
    });
  }

  async run(): Promise<void> {
    const _class = this;

    const customHeaders = {
      'Accept': 'application/json',
      'X-CSRFToken': _class.csrfToken,
    };

    const staffLicenseManager = new Vue({
      components: {DatePicker},
      template: `
        <section>
          <form class="da-forms">
            <section>
              <div v-for="resourceType in resources" class="da-form-formItem">
                <h4>{{ resourceType.desc }}</h4>
                <p v-for="verb in availableVerbs">
                  <input type="radio" :name="resourceType.value + '-radio-group'" :value="verb.value"
                         v-model="resourceType.verb">
                  {{ verb.name }}
                </p>
              </div>
            </section>
            <button @click="handleSave" class="da-components-button da-btn-solid">SAVE</button>
          </form>
        </section>
      `,
      data() {
        return {
          licenseId: _class.appData.license_id,
          userId: _class.appData.user_id,
          resources: _class.appData.resources,
          availableVerbs: _class.appData.available_verbs,
          availableResourceTypes: _class.appData.available_resource_types,
        };
      },
      methods: {
        async handleSave(e) {
          e.preventDefault();

          const mapResourcesFunction = resource => `${resource.value}:${resource.verb}`;

          const resources = this.resources.map(mapResourcesFunction).toString();

          const data = {
            'license_id': this.licenseId,
            'user_id': this.userId,
            'resources': resources,
          };

          await _class.service.postFormDataForManager(data, customHeaders);
          location.reload();
        },
        async handleDelete(e) {
          e.preventDefault();

          await _class.service.delete({'license_id': this.licenseId}, customHeaders);
          location.reload();
        }
      },
    });
    staffLicenseManager.$mount(this.mountPoint);
  }
}
