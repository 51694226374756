import Vue from "vue";
import PassportLangSelector from "./components/PassportLangSelector.vue";
import 'vue-multiselect/dist/vue-multiselect.min.css';
import Multiselect from "vue-multiselect";
import PassportEducationPanel from "./components/PassportEducationPanel.vue";
import CareerInterestsPanel from './components/CareerInterestsPassport.vue';
import Collapse from './vendor/fish-ui/src/components/Collapse.vue';
import CollapseItem from './vendor/fish-ui/src/components/CollapseItem.vue';
import AssetUploadService from "./services/AssetUploadService";
import SingleFileUploader from './components/Uploaders/SingleFileUploader.vue';
import FileUploader from './components/Uploaders/FileUploader.vue';
import FileListPreviewer from './components/Previewers/FileListPreviewer.vue';

// register globally
Vue.component("multiselect", Multiselect);
Vue.component(Collapse.name, Collapse);
Vue.component(CollapseItem.name, CollapseItem);


export default class PassportFormManager {
    protected formApp: any;
    protected SPAElement: HTMLElement;
    protected appData:any;
    protected fluentInputField;
    protected proficientInputField;
    protected resumeFileInput;

    //protected proficientLangs: HTMLElement;

    constructor(appElement: HTMLElement, appContainer:HTMLElement) {
        this.SPAElement = appElement;
        this.formApp = appContainer;
        this.appData = JSON.parse(window.diva.ui.data);
        this.fluentInputField = this.formApp.querySelector('input[name="fluent_languages"]');
        this.proficientInputField = this.formApp.querySelector('input[name="proficient_languages"]');
        this.resumeFileInput = this.formApp.querySelector('input[name="resume_file_uploader"]');
        this.run();
    }

    parseClientDataItem(value) {
        let parsed = '';

        if ((value == null) || (value =='')) {
            return '';
        }
        try {
            parsed = JSON.parse(value);
        }
        catch(e) {
            console.log('Unable to parse field value');
        }

        return parsed;

    }

    hydrateEduPanel(): any {
        let eduStatusField:HTMLInputElement = this.formApp.querySelector('input[name="educational_status"]');
        let gradYearField:HTMLInputElement = this.formApp.querySelector('input[name="graduation_year"]');
        let cumulativeGPAField:HTMLInputElement = this.formApp.querySelector('input[name="cumulative_gpa"]');
        let schoolField:HTMLInputElement = this.formApp.querySelector('input[name="institution"]');
        let eduMajorField:HTMLInputElement = this.formApp.querySelector('input[name="major_minor_field_of_study"]');
        let skillsField:HTMLInputElement  = this.formApp.querySelector('input[name="achievements_and_skills"]');

        let eduMajorFieldValue: string = eduMajorField.value;
        if (eduMajorFieldValue.startsWith('[') && eduMajorFieldValue.endsWith(']')) {
            eduMajorFieldValue = eduMajorFieldValue.slice(1, eduMajorFieldValue.length - 1);
            eduMajorFieldValue = eduMajorFieldValue.trim();
        }

        let eduMajorFieldValues: string[] = eduMajorFieldValue.split(',').map((value) => {
            value = value.trim();
            if (value.startsWith('\'') && value.endsWith('\'')) {
                value = value.slice(1, value.length - 1);
            }
            return value;
        });

        const eduPanelState = {
            'educational_status': eduStatusField.value,
            'graduation_year': gradYearField.value,
            'cumulative_gpa': cumulativeGPAField.value,
            'institutionID': schoolField.value,
            'major_minor_field_of_study': eduMajorFieldValues,
            'institution': this.appData.institution,
            'skills': skillsField.value && skillsField.value != '' ? skillsField.value.split(',') : []
        };

        return eduPanelState;
    }

    hydrateCareersPanel(): any {
        let gradSchoolPlansField:HTMLInputElement = this.formApp.querySelector('input[name="graduate_school_plans"]');
        let gradSchoolSelectionField:HTMLInputElement = this.formApp.querySelector('input[name="area_of_study"]');

        let internshipPlansField:HTMLInputElement = this.formApp.querySelector('input[name="internship_interests"]');
        let internshipSelectionField:HTMLInputElement = this.formApp.querySelector('input[name="career_interests"]');

        const careersState = {
            'grad_school_plans': gradSchoolPlansField.value,
            'internship_plans': internshipPlansField.value,
            'grad_school_selections': gradSchoolSelectionField.value,
            'internship_selections': internshipSelectionField.value
        };

        return careersState;
    }

    run() {
        const _class = this;
        const data = this.appData.languages;
        const languageOpts = Object.keys(data).map(key => data[key]).sort();
        const fieldsData = this.appData.fields_of_study;
        const world_regions = this.appData.world_regions;
        const countries = this.appData.countries;
        const fieldsOpts = Object.keys(fieldsData).map(key => fieldsData[key]);
        const world_opts = Object.keys(world_regions).map(key => world_regions[key]);
        const us_states = this.appData.us_states;
        const selectedFluencies = _class.parseClientDataItem(_class.fluentInputField.value);
        const selectedProficiencies = _class.parseClientDataItem(_class.proficientInputField.value);
        const skillsOptions = this.appData.skills_list;

        const collegeMajors = this.appData.college_majors;
        const eduAppData = this.hydrateEduPanel();
        const careersData = this.hydrateCareersPanel();
        const resumeData = this.appData.resume;
        const passportStatus = this.appData.passportStatus;

        const studentPassportApp = new Vue({
            el: _class.SPAElement,
            components: {
                Collapse,
                CollapseItem,
                PassportLangSelector,
                Multiselect,
                PassportEducationPanel,
                CareerInterestsPanel,
                SingleFileUploader,
                FileUploader
            },
            data: {
                languages: languageOpts,
                countriesList: countries,
                stateslist: us_states,
                collegeMajors: collegeMajors,
                passportForm: _class.SPAElement,
                appContainer: _class.formApp,
                travelDestinationsFieldValue: [],
                fluentInput: _class.fluentInputField,
                proficientInput: _class.proficientInputField,
                fluentLangValue: selectedFluencies,
                proficientLangValue: selectedProficiencies,
                educationStatus: eduAppData.educational_status,
                gradYearList: _class.appData.graduation_year_options,
                gradYear: _class.appData.graduation_year,
                cumulativeGPA: eduAppData.cumulative_gpa,
                institution: eduAppData.institution,
                studyMajors: eduAppData.major_minor_field_of_study,
                achievementsAndSkills: skillsOptions,
                userSkills: eduAppData.skills,
                graduateSchoolPlansValue: careersData.grad_school_plans,
                internshipPlansValue: careersData.internship_plans,
                graduateSchoolSelectionsValue: careersData.grad_school_selections,
                internshipSelectionsValue: careersData.internship_selections,
                careersAppPanel: null,
                assetService: AssetUploadService,
                uploaderComponent: FileUploader,
                resumeFileInfo: resumeData,
                fileListComponent: FileListPreviewer,
                parseClientData: _class.parseClientDataItem,
                uploaderProps: {
                    label: "Click Here to Upload your resume",
                    fileInput: _class.resumeFileInput,
                    enableSelection: (_class.appData.resume == null)
                }
            },
            created() {
                let travelDestinationsField:HTMLInputElement = this.appContainer.querySelector('input[name="international_travel_destinations"]');
                let destinationKeys:Array<String> = this.parseClientData(travelDestinationsField.value);
                this.travelDestinationsFieldValue = this.hydrateTravelDestinations(destinationKeys);
            },
            mounted() {

            },
            methods: {
                hydrateTravelDestinations(keys){
                    const selectedCountries = [];
                    this.countriesList.forEach(x => {
                        if(keys.indexOf(x.value) >= 0) {
                            selectedCountries.push({
                                name: x.name,
                                value: x.value
                            });
                        }
                    });

                    return selectedCountries;
                },

                toggleBooleanProperty(e) {
                    let formElement = e.target;
                    let formValue = e.target.value;

                    if ((formElement.getAttribute('name') == 'graduate_school_plans') && (formValue == 'True')) {
                        this.graduateSchoolPlans = true;
                    }
                    else {
                        this.graduateSchoolPlans = false;
                    }

                    if ((formElement.getAttribute('name') == 'internship_interests') && (formValue == 'True')) {
                        this.internshipInterests = true;
                    }
                    else {
                        this.internshipInterests = false;
                    }
                },

                handleDestinationOptionsChanged(value, id) {
                    let options = value.map(x => x.value);
                    //this.travelDestinationsField.value = options;
                    let travelDestinationsField:HTMLInputElement = this.appContainer.querySelector('input[name="international_travel_destinations"]');
                    travelDestinationsField.value = JSON.stringify(options);
                },

                handleFluentLanguageSelect(data) {
                    //this.travelDestinationsField.value = options;
                    let fluentLangField:HTMLInputElement = this.appContainer.querySelector('input[name="fluent_languages"]');
                    fluentLangField.value = JSON.stringify(data.value);
                },

                handleProficientLanguageSelect(data) {
                    //this.travelDestinationsField.value = options;
                    let proficientLangField:HTMLInputElement = this.appContainer.querySelector('input[name="proficient_languages"]');
                    proficientLangField.value = JSON.stringify(data.value);
                },

                handleEduStatusChanged(data) {
                    console.log('Edu Status has changed');
                    let eduStatusField:HTMLInputElement = this.appContainer.querySelector('input[name="educational_status"]');
                    eduStatusField.value = data;
                },

                handleGradYearChanged(data) {
                    console.log('Graduation year has changed');
                    let gradYearField:HTMLInputElement = this.appContainer.querySelector('input[name="graduation_year"]');
                    gradYearField.value = data;
                },


                handleGPAInputChanged(data) {
                    console.log('GPA Input Changed');
                    let cumulativeGPAField:HTMLInputElement = this.appContainer.querySelector('input[name="cumulative_gpa"]');
                    cumulativeGPAField.value = data;
                },


                handleSchoolSelectionChanged(data) {
                    console.log('School selection changed');
                    let schoolField:HTMLInputElement = this.appContainer.querySelector('input[name="institution"]');
                    schoolField.value = data;
                },


                handleMajorSelectionChanged(data) {
                    console.log('Major selection changed');
                    let eduMajorField:HTMLInputElement = this.appContainer.querySelector('input[name="major_minor_field_of_study"]');
                    eduMajorField.value = data;
                },


                handleGradSchoolPlansChange(data) {
                    let gradSchoolPlansField:HTMLInputElement = this.appContainer.querySelector('input[name="graduate_school_plans"]');
                    gradSchoolPlansField.value = data.target.value;
                },


                handleInternshipPlansChange(data) {
                    let internshipPlansField:HTMLInputElement = this.appContainer.querySelector('input[name="internship_interests"]');
                    internshipPlansField.value = data.target.value;
                },


                handleInternshipSelectionChange(data) {
                    let intershipSelectionField:HTMLInputElement = this.appContainer.querySelector('input[name="career_interests"]');
                    intershipSelectionField.value = data;
                },


                handleGradSchoolSelectionChange(data) {
                    let gradSchoolSelectionField:HTMLInputElement = this.appContainer.querySelector('input[name="area_of_study"]');
                    gradSchoolSelectionField.value = data;
                },


                handleSkillSelectionChanged(data) {
                    let skillsField:HTMLInputElement  = this.appContainer.querySelector('input[name="achievements_and_skills"]');
                    skillsField.value = data;
                },

                handleFileDelete(data) {
                    console.log("File Delete");
                },

                handleUploaderChange(data) {
                    this.uploaderProps.enableSelection = !data;
                    location.reload();
                }

            }
        });

    }
}
