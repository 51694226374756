import HttpService from './HttpService';


export default class LicenseService extends HttpService {
  constructor() {
    super('/licenses/api');
  }

  postFormDataForManager(data: Object, customHeaders: Object) {
    this.endpoint = '/licenses/api/manager';
    return this.postFormData(data, customHeaders);
  }
}
